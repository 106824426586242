<template>
    <div class="div-box2">
        <div class="div-centonx">
            <div class="div-topzt">{{ articleTheme }}</div>
            <div class="div-topdes">
                <span>作者：{{ userName }}</span>
                <span>来源：E-Sport</span>
                <span>发布时间：{{ createTime }}</span>
                <span>热度：{{ articleNumber }}</span>
            </div>
            <div class="w-e-text">
                <div class="div-mainnr" v-html="articleContent">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import articleApi from '@/api/article';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElImage, ElMessage } from 'element-plus';
export default {
    setup() {
        // 表格数据
        const router = useRouter();
        // 表单数据
        const articleTheme = ref('');
        const articleNumber = ref(0);
        const articleContent = ref('');
        const userName = ref('');
        const createTime = ref('');


        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = () => {
            articleApi.getById(router.currentRoute.value.query.id).then((res) => {
                if (res.code == 200) {
                    articleTheme.value = res.data.articleTheme;
                    articleNumber.value = res.data.articleNumber;
                    articleContent.value = res.data.articleContent;
                    userName.value = res.data.userName;
                    createTime.value = res.data.createTime;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
        });
        return {
            fetchData,
            articleTheme,
            articleNumber,
            articleContent,
            userName,
            createTime
        };
    },
}
</script>

<style>
.div-box2 {
    width: 60%;
    height: auto;
    background-color: #fafafa;
    /* 假设这是卡片的父容器 */
    display: flex;
    flex-direction: column;
    /* 设置flex方向为列，确保卡片垂直排列 */
    align-items: stretch;
    /* 确保卡片填满可用空间 */
    margin: auto;
    margin-top: 60px;
}

.div-centonx {
    padding: 40px;
}

.div-topdes {
    font-size: 14px;
    margin-top: 10px;
}

.div-topdes span {
    margin-right: 18px;
}

.div-topzt {
    font-size: 22px;
    font-weight: bold;
}
</style>