import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/category/" + data);
  },
  getListUser() {
    return http.get("/category");
  },
  save(data) {
    return http.post("/category", data);
  },
  delete(data) {
    return http.delete("/category/"+data);
  },
  getPage(params) {
    return http.get("/category/page", { params });
  },
};
