<template>
    <div class="div-box-walletmain">
        <div class="div-box-walletmain-top">
            <h3>我的钱包</h3>
        </div>
        <div class="div-box-walletmain-middle">
            <div class="div-box-walletmain-middle-item">
                账户余额 <span style="margin-left: 25px; font-weight: bold; color: #FFD700;">¥ {{ hovaMoney }}</span>
                <el-button type="primary" @click="Withdrawaldialog = true">提现</el-button>
            </div>
            <div class="div-box-walletmain-middle-item">
                <span style="margin-right: 30px;">充值金额</span>
                <el-input v-model="params.money" style="width: 240px; height: 35px;" placeholder="请输入充值金额" />
            </div>
            <div class="div-box-walletmain-middle-item" style="display: flex; line-height: 35px;">
                <span style="margin-right: 30px;">充值方式</span>
                <ul style="display: flex;">
                    <li class="div-box-cz-item1" value="1" @click="onGo('1')">
                        <el-icon>
                            <Shop />
                        </el-icon>
                        支付宝
                    </li>
                    <li class="div-box-cz-item1" value="2" @click="onGo('2')">
                        <el-icon>
                            <WalletFilled />
                        </el-icon>
                        微信
                    </li>
                </ul>
            </div>
            <div class="div-box-walletmain-middle-item">
                <el-button type="primary" style="height: 35px; margin-left: 93px;"
                    @click="savaAdd(params)">立即充值</el-button>
            </div>
            <!-- 提现弹窗 -->
            <el-dialog v-model="Withdrawaldialog" title="提现" width="500">
                <el-form :model="WithdrawalFrom">
                    <el-form-item label="提现金额" label-width="100">
                        <el-input v-model="WithdrawalFrom.userBalance" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="提现账户" label-width="100">
                        <el-input disabled v-model="WithdrawalFrom.userTel"  autocomplete="off" />
                    </el-form-item>
                </el-form>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="Withdrawaldialog = false">取消</el-button>
                        <el-button type="primary" @click="Withdrawal">
                            确认
                        </el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
        <div class="div-box-walletmain-bottom">
            <pre class="text-class-bottom">
        充值须知
        1. 单笔限额5-50000元；
        2. 充值退款或提现会产生1%服务费；
        3. 帐号违规资金转移套现将被冻结处理。
    </pre>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import userApi from '@/api/user';
export default {
    setup() {
        //充值方式
        const status = ref("");
        const user = JSON.parse(localStorage.getItem('user'));
        const hovaMoney = ref(0)
        const router = useRouter();
        const Withdrawaldialog = ref(false)
        const rechargeId = ref(null);
        const WithdrawalFrom = reactive({
            userBalance: null,
            userTel:'',
            userId:user.userId
        })
        //提现方法
        const Withdrawal = () =>{
            if(WithdrawalFrom.userBalance >= hovaMoney.value){
                ElMessage.error("没有足够余额进行提现");
                return;
            }
            if(WithdrawalFrom.userBalance == null){
                ElMessage.error("请填写提现余额");
                return;
            }
            userApi.withdrawal(WithdrawalFrom).then((res)=>{
                if(res.code == 200){
                    Withdrawaldialog.value = false;
                    WithdrawalFrom.userBalance = null;
                    fetchData();
                    ElMessage.success("提现成功，预计2小时内到账")
                }else{
                    ElMessage.success("提现失败")
                }
            })
        }
        //选择充值方式方法
        const onGo = (index) => {
            status.value = index;
            document.querySelectorAll("li").forEach((li) => {
                if (li.value == index) {
                    if (li.classList.contains("div-box-cz-item1")) {
                        li.classList.replace("div-box-cz-item1", "div-box-cz-item2");
                    }
                } else {
                    li.classList.replace("div-box-cz-item2", "div-box-cz-item1");
                }
            });
        }
        const params = reactive({
            userId: user.userId,
            money: '',
            status: status,
        })
        const fetchData = async () => {

            // 模拟异步操作
            userApi.getById2(user.userId).then((res) => {
                if (res.code == 200) {
                    hovaMoney.value = res.data.userBalance;
                    WithdrawalFrom.userTel = res.data.userTel;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        onMounted(() => {
            let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
            if (user) {
                let date = new Date().getTime();
                let OneHour = 3600000;
                // 如果大于就是过期了
                if (date - user.startTime > OneHour) {
                    router.push({
                        path: "/"
                    });
                    return;
                } else {
                    user.startTime = date;
                    localStorage.setItem("user", JSON.stringify(user));
                }
            }
            fetchData()
        });
        //进行充值方法
        const savaAdd = () => {
            if (params.money == "") {
                ElMessage.error("请输入充值金额");
                return;
            }
            if (parseFloat(params.money) < 5) {
                ElMessage.error("单笔充值金额不能低于5元");
                return;
            }
            if (params.status == "") {
                ElMessage.error("请选择充值方式");
                return;
            }
            // 模拟异步操作
            userApi.saveAdds(params).then((res) => {
                if (res.code == 200) {
                    rechargeId.value = res.data;
                    //等于1时调用支付宝接口
                    if (params.status == 1) {
                        window.open('http://localhost:9090/api/alipay/pay?rechargeId=' + rechargeId.value);
                    } else {
                        //调用微信接口
                        ElMessage.success("充值成功");
                    }
                    params.money = '';
                    params.status = '';
                    onGo(params.status)
                    fetchData()
                } else {
                    ElMessage.error("充值失败");
                }
            })

        }

        return {
            onGo,
            status,
            params,
            savaAdd,
            fetchData,
            hovaMoney,
            rechargeId,
            Withdrawaldialog,
            WithdrawalFrom,
            Withdrawal
        }
    }
}
</script>

<style>
.div-box-walletmain {
    width: 100%;
    height: 90%;
}

.div-box-walletmain-middle {
    width: 100%;
    height: 60%;
}

.div-box-walletmain-top {
    height: 10%;
}

.div-box-walletmain-bottom {
    width: 100%;
    height: 25%;
}

.div-box-cz-item1 {
    width: 150px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-right: 8px;
    border: 1px solid rgb(158, 157, 157);
}

.text-class-bottom {
    text-align: left;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: darkgrey;
    background-color: #FEFCF7;
    border-radius: 5px;
}

.div-box-cz-item2 {
    width: 150px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-right: 8px;
    color: rgb(248, 198, 34);
    border: 1px solid rgb(248, 198, 34);
}

.div-box-cz-item1:hover {
    background-color: #ffffff;
    color: rgb(248, 198, 34);
    /* 更改点击时的颜色和字体 */
}

.div-box-walletmain-middle-item {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
}
</style>