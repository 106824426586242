import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/article/" + data);
  },
  getListUser() {
    return http.get("/article");
  },
  addHot(data) {
    return http.post("/article/addHot/" + data);
  },
  save(data) {
    return http.post("/article", data);
  },
  delete(data) {
    return http.delete("/article/"+data);
  },
  getPage(params) {
    return http.get("/article/page", { params });
  },
  getPage2(params) {
    return http.get("/article/page/system", { params });
  },
};
