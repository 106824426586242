<template>
    <div class="div-box-purchasemain">
        <div class="div-box-purchasmain-top">
            <h3>购买订单</h3>
        </div>
        <div class="div-box-purchasmain-middle">
            <div style="font-size: 15px">
                <span style="margin-left: 10%;">商品</span>
                <span style="margin-left: 22%;">售价</span>
                <span style="margin-left: 5%;">卖家</span>
                <span style="margin-left: 15%;">时间</span>
                <span style="margin-left: 19%;">详情</span>
            </div>
            <div class="purchasmain-goods-card" v-for="i in tableData" :key="i.goodsId">
                <div class="goods-card-main">
                    <div style="width: 35%;">
                        <div style="display: flex; align-items: center;">
                            <el-image style="width: 70px; height: 45px" :src="i.goodsPhoto" fit="fill" />
                            <span>{{ i.waresName }}</span>
                        </div>
                    </div>
                    <div style="width: 10%;">
                        <span>{{ i.waresPrice }}</span>
                    </div>
                    <div style="width: 15%;">
                        <span>{{ i.sellerName }}</span>
                    </div>
                    <div style="width: 25%;">
                        <span>{{ i.createTime }}</span>
                    </div>
                    <div style="width: 15%; height: 100%;">
                        <div v-if="i.goodsState == 0 && i.is_true_goods != 0">
                            <span>等待卖家发货</span>
                            <el-button v-if="i.isComplaint != '1'" type="primary" size="small" style="width: 100px; margin-left: -2px; margin-top: 5px;" @click="openComplaintDailg(i.goodsId)">订单投诉</el-button>
                            <div v-else style="margin-top: 5px;">
                            <span style="color: red">
                                投诉处理中
                            </span>
                            </div>
                        </div>
                        <div v-else-if="i.goodsState == 1 && i.is_true_goods != 0">
                            <el-button type="primary" size="small" style="width: 100px; margin-left: -2px;" @click="openDailg(i.goodsId)">确认收货</el-button>
                            <el-button v-if="i.isComplaint != '1'" type="primary" size="small" style="margin-top: 5px; width: 100px; margin-left: -2px;" @click="openComplaintDailg(i.goodsId)">订单投诉</el-button>
                            <div v-else style="margin-top: 5px;">
                            <span style="color: red">
                                投诉处理中
                            </span>
                            </div>
                        </div>
                        <div v-else-if="i.goodsState == 2 && i.is_true_goods != 0">
                            <span>已确认收货</span>
                        </div>
                        <div v-else-if="i.goodsState == 3 && i.is_true_goods != 0">
                            <el-icon style="color: green;"><CircleCheckFilled /></el-icon>交易成功
                            <span>{{ i.goodsCode }}</span>
                        </div>
                        <div v-else>
                            <el-icon style="color: red;"><CircleCloseFilled /></el-icon>交易失败
                            <span>{{ i.goodsCode }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination style="height: 15%;" small @size-change="handlePageSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" background
                layout="prev, pager, next" :total="totalItems">
            </el-pagination>
        </div>
        <el-dialog v-model="centerDialogVisible" title="确认收货" width="500">
            <span>请确认已经收到相关货物！</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="centerDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="goToTakeDelivery">确认</el-button>
                </div>
            </template>
        </el-dialog>
        <!-- 订单投诉的对话框弹窗 -->
        <el-dialog v-model="dialogVisible" title="订单投诉" width="500">
            <el-form :model="form">
                <el-form-item label="请选择投诉类型" label-width="120">
                    <el-select v-model="form.cptId" placeholder="请选择投诉类型">
                        <el-option v-for="cpt in categoryIds" :key="cpt.cptId" :label="cpt.cptName"
                            :value="cpt.cptId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="请输入投诉描述" label-width="120">
                    <el-input v-model="form.complaintContent" type="textarea" autocomplete="off" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="addCompla">
                        提交信息
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import infogoodsApi from '@/api/infogoods';
import cptcategoryApi from '@/api/cptcategory';
import complaintgoodsApi from '@/api/complaintgoods';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { ElImage, ElMessage } from 'element-plus';
export default {
    setup() {
        const router = useRouter();
        // 表格数据
        const tableData = reactive([]);
        const currentPage = ref(1); // 当前页码
        const pageSize = ref(5); // 每页显示的数据条数
        const totalItems = ref(10); // 数据的总条数
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.userId;
        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            userId: userId,
            status: 1
        })
        const goodsIdindex = ref(null);
        const centerDialogVisible = ref(false)
        const openDailg = (goodsId) =>{
            centerDialogVisible.value = true;
            goodsIdindex.value = goodsId;
        }
         //投诉订单弹窗控制变量
         const dialogVisible = ref(false)
        //设置投诉分类值
        const categoryIds = ref([]);
        //投诉订单生成发起变量
        const form = reactive({
            userId: user.userId,
            goodsId: null,
            complaintContent:'',
            cptId: null 
        })
        //打开投诉弹窗
        const openComplaintDailg = (goodsId) =>{
            dialogVisible.value = true;
            form.goodsId = goodsId;
        }
        //获取投诉分类列表
        const getCptList = () =>{
            cptcategoryApi.getListUser().then((res) =>{
                if (res.code == 200) {
                    categoryIds.value = res.data;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        }
        //提交投诉分类列表
        const addCompla = () =>{
            complaintgoodsApi.addComplaint(form).then((res) => {
                if(res.code == 200){
                    ElMessage.success("投诉成功，我们会尽快处理！")
                    dialogVisible.value = false;
                    fetchData();
                    form.goodsId = null;
                    form.complaintContent = '';
                    form.cptId = null;
                }else{
                    ElMessage.error("投诉失败")
                }
            })
        }
        //进行收货
        const goToTakeDelivery = () =>{
            infogoodsApi.goToTakeDelivery(goodsIdindex.value).then((res) => {
                if(res.code == 200){
                    ElMessage.success("收货成功")
                    centerDialogVisible.value = false;
                    goodsIdindex.value = null;
                    fetchData();
                }else{
                    ElMessage.error("收货失败")
                }
            })

        }
        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });
        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };
        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            infogoodsApi.getPage2(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
            getCptList();
        });
        return {
            tableData,
            currentPage,
            pageSize,
            totalItems,
            params,
            paginatedData,
            handlePageSizeChange,
            handleCurrentChange,
            fetchData,
            goodsIdindex,
            centerDialogVisible,
            openDailg,
            goToTakeDelivery,
            dialogVisible,
            openComplaintDailg,
            form,
            categoryIds,
            getCptList,
            addCompla

        }
    }
}

</script>

<style>
.div-box-purchasemain {
    width: 100%;
    height: 90%;
}

.div-box-purchasmain-top {
    height: 10%;
}

.div-box-purchasmain-middle {
    width: 100%;
    height: 85%;
    text-align: left;
    margin-left: 10px;
}

.purchasmain-goods-card {
    /* 每个卡片的样式 */
    display: block;
    /* 这不是必需的，因为div默认就是块级元素 */
    margin-top: 1.5%;
    /* 示例：卡片之间的间距 */
    padding: 10px;
    background-color: #fcf8f8;
    border-radius: 5px;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    height: 16%;
    /* 确保卡片宽度填满父容器 */
    box-sizing: border-box;
    /* 包括边框和内边距在卡片的总宽度内 */
}

.goods-card-main {
    display: flex;
    align-items: center;
    font-size: 12px;
}
</style>