<template>
    <div style="display: flex; width: 100%; height: 50%;">
        <div style="width: 70%; height: 100%;">
            <div ref="oneEcharts" style="width: 100%; height: 100%;">
            </div>
        </div>
        <div style="width: 30%; height: 100%;">
            <!-- 用户数量卡片 -->
            <el-card style="height: 23%; margin-bottom: 1.5%;">
                <div class="clearfix-x">
                    <el-icon name="user" style="font-size: 30px; margin-right: 10px;">
                        <User />
                    </el-icon>
                    <span>用户数量：</span>
                    <span class="number">{{ userNumber }}</span>
                </div>
                <!-- 卡片内容 -->
            </el-card>
            <!-- 游戏数量卡片 -->
            <el-card style="height: 23%; margin-bottom: 1.5%;">
                <div class="clearfix-x">
                    <el-icon name="game" style="font-size: 30px; margin-right: 10px;">
                        <Platform />
                    </el-icon>
                    <span>游戏数量：</span>
                    <span class="number">{{ gameNumber }}</span>
                </div>
                <!-- 卡片内容 -->
            </el-card>

            <!-- 订单数量卡片 -->
            <el-card style="height: 23%; margin-bottom: 1.5%;">
                <div class="clearfix-x">
                    <el-icon name="order" style="font-size: 30px; margin-right: 10px;">
                        <GoodsFilled />
                    </el-icon>
                    <span>订单数量：</span>
                    <span class="number">{{ goodsNumber }}</span>
                </div>
                <!-- 卡片内容 -->
            </el-card>

            <!-- 今日订单数量卡片 -->
            <el-card style="height: 23%;">
                <div slot="header" class="clearfix-x">
                    <el-icon name="date" style="font-size: 30px; margin-right: 10px;">
                        <PriceTag />
                    </el-icon>
                    <span>今日订单数量：</span>
                    <span class="number">{{ dayNumber }}</span>
                </div>
                <!-- 卡片内容 -->
            </el-card>
        </div>
    </div>
    <div style="display: flex; width: 100%; height: 49%;">
        <div style="width: 60%; height: 100%;">
            <div ref="towEcharts" style="width: 100%; height: 100%;">
            </div>
        </div>
        <div style="width: 40%; height: 100%;">
            <div ref="threeEcharts" style="width: 100%; height: 100%;">
            </div>
        </div>
    </div>
</template>

<script setup>
import * as echarts from "echarts";
import userApi from '@/api/user';
import gameinfoApi from '@/api/gameinfo';
import infogoodsApi from '@/api/infogoods';
import { ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
const userNumber = ref(0);
const gameNumber = ref(0);
const goodsNumber = ref(0);
const dayNumber = ref(1);
const oneEcharts = ref(null);
const towEcharts = ref(null);
const threeEcharts = ref(null);
const zxTrendData = ref({
    eventDate: null,
    dailyCount: null
});
const zzTrendData = ref({
    eventDate: null,
    dailyCount: null
});

const btTrendData = ref([{}]);

const router = useRouter();
//获取用户数量
const getUserNumber = () => {
    userApi.getListUser().then(res => {
        if (res.code == 200) {
            userNumber.value = res.data.length
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取游戏数量
const getGameNumber = () => {
    gameinfoApi.getListUser().then(res => {
        if (res.code == 200) {
            gameNumber.value = res.data.length
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取订单数量
const getGoodsNumber = () => {
    infogoodsApi.getListUser().then(res => {
        if (res.code == 200) {
            goodsNumber.value = res.data.length
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取今日订单数量
const getDayDoodsNumber = () => {
    infogoodsApi.getDayDoods().then(res => {
        if (res.code == 200) {
            dayNumber.value = res.data.length
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取近七天订单数量
const getTrendData = () => {
    infogoodsApi.getTrendData().then(res => {
        if (res.code == 200) {
            zxTrendData.eventDate = res.data.eventDate
            zxTrendData.dailyCount = res.data.dailyCount
            initOneEchats(zxTrendData)
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取热度前十的游戏排名
const getHotGame = () => {
    gameinfoApi.getHotGame().then(res => {
        if (res.code == 200) {
            zzTrendData.eventDate = res.data.eventDate
            zzTrendData.dailyCount = res.data.dailyCount
            initTowEchats(zzTrendData);
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

//获取游戏分类数据
const getGroups = () => {
    gameinfoApi.getGroups().then(res => {
        if (res.code == 200) {
            btTrendData.value = res.data;
            initThreeEchats(btTrendData.value);
        } else {
            ElMessage.error("获取用户数据失败！")
        }
    })
}

const initOneEchats = (zxTrendData) => {
    //let chartDom = document.getElementById('oneEcharts');
    let myChart = echarts.init(oneEcharts.value);
    let option;

    option = {
        title: {
            text: '游戏交易趋势图'
        },
        xAxis: {
            type: 'category',
            data: zxTrendData.eventDate
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: zxTrendData.dailyCount,
                type: 'line'
            }
        ]
    };
    option && myChart.setOption(option);
}

const initTowEchats = (zzTrendData) => {
    //let chartDom = document.getElementById('towEcharts');
    let myChart = echarts.init(towEcharts.value);
    let option;

    option = {
        title: {
            text: '游戏热度排名前十'
        },
        xAxis: {
            type: 'category',
            data: zzTrendData.eventDate,
            axisLabel: {
                alignWithLabel: true,
                interval: 0, // 显示所有标签，避免因间隔设置导致部分标签不显示
                lineHeight: 18, // 可调整行高以适应多行文本
                rotate: -45,
                fontSize: 10,
                fontWeight: 'bold',
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: zzTrendData.dailyCount,
                type: 'bar'
            }
        ]
    };

    option && myChart.setOption(option);
}

const initThreeEchats = (btTrendData) => {
    //let chartDom = document.getElementById('threeEcharts');
    let myChart = echarts.init(threeEcharts.value);
    let option;

    option = {
        title: {
            text: '游戏类别分布图',
            subtext: '分布情况',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: '详细数据',
                type: 'pie',
                radius: '50%',
                data: btTrendData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    option && myChart.setOption(option);
}

onMounted(() => {
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if (user) {
        let date = new Date().getTime();
        let OneHour = 3600000;
        // 如果大于就是过期了
        if (date - user.startTime > OneHour) {
            router.push({
                path: "/"
            });
            return;
        } else {
            user.startTime = date;
            localStorage.setItem("user", JSON.stringify(user));
        }
    }
    getTrendData();
    getHotGame();
    getGroups();
    getUserNumber();
    getGameNumber();
    getGoodsNumber();
    getDayDoodsNumber();
});
</script>

<style>
.grid-content {
    border: 1px solid #eee;
    text-align: center;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.clearfix-x {
    height: 100%;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-card__body {
    height: 100% !important;

}

.number {
    float: right;
    font-weight: bold;
    font-size: 20px;
}
</style>