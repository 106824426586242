import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import axios from 'axios'
import ElMessage from 'element-plus'
import * as ELIcons from '@element-plus/icons-vue'
import * as echarts from 'echarts'
import 'default-passive-events'

const app = createApp(App)

for(let iconName in ELIcons){
    app.component(iconName,ELIcons[iconName])
   }
//全局挂载ElementPlus
app.use(ElementPlus, {locale: zhCn})
//全局挂载路由方法
app.use(router)
//挂载页面

axios.defaults.baseURL = 'http://127.0.0.1:9090/api'


app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$echarts = echarts
app.mount('#app')
