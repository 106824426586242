<template>
    <div id="app">
        <!-- 新增记录按钮 -->
        <el-button @click="addRecord" >新增</el-button>
        <el-button type="primary" style="float: right;" @click="fetchData">搜索</el-button>
        <el-input style="width: 200px; cursor: pointer; float: right;" suffix-icon="el-icon-search" placeholder="请输入昵称"
            v-model="params.nickName"></el-input>
        <!-- 用户表格 -->
        <el-table :data="tableData" style="width: 100%">
            <!-- 表格列定义 -->
            <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
            <el-table-column prop="userAccount" label="账号" width="180" align="center"></el-table-column>
            <el-table-column prop="userTel" label="手机号" width="180" align="center"></el-table-column>
            <el-table-column prop="userIdCard" label="身份证号" width="200" align="center"></el-table-column>
            <el-table-column prop="nickName" label="昵称" width="150" align="center"></el-table-column>
            <el-table-column prop="userRole" label="角色" width="150" align="center"></el-table-column>
            <el-table-column prop="userBalance" label="余额" width="150" align="center"></el-table-column>
            <el-table-column label="是否注销" align="center">
                <template #default="scope">
                    <el-tag v-if="scope.row.userIsTrue == 0" type="success">正常</el-tag>
                    <el-tag v-else type="danger">已注销</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="是否禁用">
                <template #default="scope">
                    <el-tag v-if="scope.row.isTrue == 0" type="success">正常</el-tag>
                    <el-tag v-else type="danger">已封禁</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button @click="editRecord(scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" @click="deleteRecord(scope.row.userId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="totalItems">
        </el-pagination>

        <!-- 编辑/新增对话框 -->
        <el-dialog v-model="dialogVisible" title="新增/编辑记录">
            <el-form :model="form" :rules="rules" label-width="120px">
                <el-form-item label="账号" prop="userAccount">
                    <el-input v-model="form.userAccount"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="userTel">
                    <el-input v-model="form.userTel"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="userIdCard">
                    <el-input v-model="form.userIdCard"></el-input>
                </el-form-item>
                <el-form-item label="昵称" prop="nickName">
                    <el-input v-model="form.nickName"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="userRole">
                    <el-select v-model="form.userRole" placeholder="请选择角色">
                        <el-option v-for="role in roles" :key="role.value" :label="role.label" :value="role.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="余额" prop="userBalance">
                    <el-input v-model="form.userBalance"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="save(form)">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import userApi from '@/api/user';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import { computed } from 'vue';
export default {
    setup() {
        // 表格数据
        const tableData = reactive([]);

        // 对话框可见性
        const dialogVisible = ref(false);

        const currentPage = ref(1); // 当前页码
        const pageSize = ref(10); // 每页显示的数据条数
        const totalItems = ref(0); // 数据的总条数

        //设置角色值
        const roles = ref([
            { label: '系统管理员', value: '系统管理员' },
            { label: '普通用户', value: '普通用户' },
        ])

        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };

        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            nickName: ''
        })

        // 表单数据
        const form = reactive({
            userId: null,
            userAccount: '',
            userTel: '',
            userIdCard: '',
            nickName: '',
            userRole: '',
            userBalance: 0,
            userIsTrue: 0,
            isTrue: 0,
        });

        // 表单验证规则
        const rules = {
            userAccount: [
                { required: true, message: '请输入账号', trigger: 'blur' },
            ],
            userTel: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
            ],
            userIdCard: [
                { required: true, message: '请输入身份证号', trigger: 'blur' },
            ],
            // ... 其他表单项的验证规则
        };

        // 新增记录
        const addRecord = () => {
            dialogVisible.value = true;
            form.userId = null,
                form.userAccount = '';
            form.userTel = '';
            form.userIdCard = '';
            form.nickName = '';
            form.userRole = '';
            form.userBalance = 0;
            form.userIsTrue = 0;
            form.isTrue = 0;
        };

        // 编辑记录
        const editRecord = (record) => {
            dialogVisible.value = true;
            form.userId = record.userId;
            form.userAccount = record.userAccount;
            form.userTel = record.userTel;
            form.userIdCard = record.userIdCard;
            form.nickName = record.nickName;
            form.userRole = record.userRole;
            form.userBalance = record.userBalance;
            form.userIsTrue = record.userIsTrue;
            form.isTrue = record.isTrue;
        };

        // 删除记录
        const deleteRecord = (id) => {
            userApi.delete(id).then(res => {
                if (res.code == 200) {
                    ElMessage.success("删除成功")
                    fetchData();
                } else {
                    ElMessage.error("删除失败")
                }
            })
        }

        const save = async (form) => {
            userApi.save(form).then(res => {
                if (res.code == 200) {
                    console.log(res)
                    dialogVisible.value = false;
                    ElMessage.success("保存成功")
                    fetchData();
                } else {
                    ElMessage.error("保存失败")
                }
            })
        }

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            userApi.getPage(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        // 初始化数据，获取表格数据

        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
        });

        // 返回需要暴露给模板的响应式数据和方法
        return {
            tableData,
            dialogVisible,
            form,
            rules,
            addRecord,
            editRecord,
            deleteRecord,
            save,
            paginatedData,
            currentPage,
            pageSize,
            totalItems,
            handlePageSizeChange,
            handleCurrentChange,
            roles,
            fetchData,
            params
        };
    },
};
</script>

<style>
/* 表格样式 */
.el-table {
    width: 100%;
    margin-bottom: 20px;
}

/* 表格列样式 */
.el-table__body-wrapper {
    overflow-x: auto;
}

.el-table__body tr td {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* 对话框样式 */
.el-dialog {
    width: 50%;
    /* 或者你可以设置具体的像素值 */
}


/* 对话框内容样式 */
.el-dialog__body {
    padding: 20px;
}

/* 对话框底部按钮样式 */
.el-dialog__footer {
    text-align: right;
    padding: 10px 20px;
    border-top: 1px solid #eee;
}

/* 其他样式 */
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

.el-button {
    margin-left: 10px;
}
</style>