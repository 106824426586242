<template>
    <div class="header-container">
        <el-dropdown style="float: right; margin-right: 80px; margin-top: 8px; margin-bottom: 5px;">
            <!-- <el-avatar  v-if="users.avatarUrl" :src="users.avatarUrl" /> -->
            <el-avatar v-if="state.squareUrl" fit="fill" :src="state.squareUrl"/>
            <el-avatar v-else :src="state.circleUrl" fit="fill" />
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="goPath()">返回前台</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
export default {
    name: "Header",
    setup() {
        const router = useRouter();
        const goPath = () => {
            router.push('/showing');
        }
        const state = reactive({
            circleUrl:
                'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            squareUrl: JSON.parse(localStorage.getItem('user')).avatarUrl,
        })
        return {
            goPath,
            state,
        }
    }
}
</script>

<style>
.header-container {
    background-color: #545c64;
    height: 60px;
    width: auto;
}

.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}
</style>