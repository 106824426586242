<template>
  <div class="div-top">
    <el-menu class="el-menu-demo" mode="horizontal" background-color="#1c202b" text-color="#D3D3D3"
      active-text-color="#fff" default-active="1">
      <el-menu-item index="1" style="width: 15%; font-size: larger;" @click="onItemClick(0)">综 合</el-menu-item>
      <el-menu-item index="2" style="width: 15%; font-size: larger;" @click="onItemClick(1)">最 新</el-menu-item>
      <el-menu-item index="3" style="width: 15%; font-size: larger;" @click="onItemClick(2)">最 热</el-menu-item>
    </el-menu>
  </div>
  <div class="div-box">
    <div class="card-container">
      <el-card class="card-item" shadow="hover" v-for="item in tableData" :key="item.gameId">
        <div @click="goToshop(item.gameId)">
          <el-image style="height: 200px; margin-left: 30px;" :src="item.gamePhoto" fit="fill" />
        <!-- 卡片内容 -->
        <div slot="header" class="clearfix8">
          <span>{{ item.gameName }}</span>
        </div>
        </div>
      </el-card>
    </div>
    <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" background layout="prev, pager, next" :total="totalItems">
    </el-pagination>
  </div>
</template>

<script>
import gameinfoApi from '@/api/gameinfo';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { ElImage, ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
export default {
  setup() {
    // 表格数据
    const tableData = reactive([]);

    const currentPage = ref(1); // 当前页码
    const pageSize = ref(20); // 每页显示的数据条数
    const totalItems = ref(0); // 数据的总条数

    // 计算属性，用于获取当前页的数据
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = start + pageSize.value;
      return tableData.value.slice(start, end);
    });
    //分页查询数据填写
    const params = reactive({
      pageNum: currentPage,
      pageSize: pageSize,
      status: 0
    })
    const router = useRouter();

    const goToshop = (gameId) =>{
      router.push({ path: '/shop', query: { gameId: gameId } });
    }

    const onItemClick = (index) => {
      params.status = index;
      fetchData();
    }

    const handlePageSizeChange = (val) => {
      pageSize.value = val;
      fetchData();
      //currentPage.value = 1; // 重置到第一页
    };
    // 处理页码变化
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      fetchData();
    };
    // 假设的获取数据函数，通常这里会是API调用
    const fetchData = async () => {
      // 模拟异步操作
      gameinfoApi.getPage2(params).then((res) => {
        if (res.code == 200) {
          tableData.splice(0, tableData.length);
          tableData.push(...res.data.records);
          totalItems.value = res.data.total;
        } else {
          ElMessage.error("加载失败")
        }
      })
    };
    // 初始化数据，获取表格数据
    onMounted(() => {
      // 页面加载完成后同步调用 fetchData 方法
      fetchData();
    });

    return {
      fetchData,
      tableData,
      params,
      paginatedData,
      handlePageSizeChange,
      handleCurrentChange,
      currentPage,
      pageSize,
      totalItems,
      onItemClick,
      goToshop

    };
  },
}
</script>

<style>
.div-box {
  width: 60%;
  height: 1400px;
  margin: auto;
  background-color: #fafafa;
}

.div-top {
  width: 60%;
  margin-left: 20%;
  background-color: rgb(58, 65, 65);
  margin-top: 60px;
}

.clearfix8 {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  height: 100%;
  /* 确保容器占据全部可用高度 */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  /* 允许卡片换行 */
  justify-content: flex-start;
  /* 从左到右排列卡片 */
}

.card-item {
  flex: 0 0 23%;
  /* 四个卡片，所以每个卡片的宽度为 23% (24% - 1% 的间距) */
  margin-right: 1%;
  /* 每个卡片之间的间距 */
  margin-left: 1%;
  /* 每个卡片之间的间距 */
  margin-top: 0.5%;
  /* 每个卡片之间的垂直间距 */
  margin-bottom: 0.5%;
  box-sizing: border-box;
  /* 确保宽度包括 padding 和 border */
  height: 250px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .card-item {
    flex: 0 0 48%;
    /* 两个卡片，所以每个卡片的宽度为 48% (50% - 1% 的间距) */
    margin-right: 2%;
    /* 每个卡片之间的间距 */
  }
}

/* 媒体查询，当屏幕宽度非常小时，调整为每个卡片占据整行 */
@media (max-width: 450px) {
  .card-item {
    flex: 0 0 100%;
    /* 单个卡片占据整行 */
    margin-right: 0;
    /* 不再有右边距 */
  }
}
</style>