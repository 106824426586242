<template>
    <div class="div-box-problemmain">
        <div class="div-box-problemmain-top">
            <h3>问题反馈</h3>
        </div>
        <div class="div-box-problemmain-middle">
            <div style="margin-bottom: 50px;">
                <span>扫描下方二维码联系网站管理员</span>
            </div>
            <div>
                <img src="../../../static/联系我们1.jpg" alt="" style="width: 250px;"/>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style>
.div-box-problemmain-top {
    height: 10%;
}
.div-box-problemmain {
    width: 100%;
    height: 90%;
}
.div-box-problemmain-middle {
    width: 100%;
    height: 80%;
}
</style>