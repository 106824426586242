<template>
    <div class="div-shopdetails-main">
        <div class="div-shopdetails-top">
            <div class="div-shopdetails-top-iamge">
                <el-image style="width: 100%; height: 100%" :src="waresFrom.waresPhoto" fit="fill" />
            </div>
            <div class="div-shopdetails-top-des">
                <div class="div-shopdetails-top-des-item1">
                    <h2>{{ waresFrom.waresName }}</h2>
                </div>
                <div class="div-shopdetails-top-des-item2">
                    类型：<span style="margin-right: 20px;">{{ waresFrom.waresGroup }}</span>
                    属性：<span>虚拟物品</span>
                </div>
                <div class="div-shopdetails-top-des-item3">
                    价格：<span style="font-weight: bold; color: #eea20e;">¥ {{ waresFrom.waresPrice }}</span>
                </div>
                <div class="div-shopdetails-top-des-item4">
                    简介：<span style="font-size: 12px;">{{ waresFrom.waresDetails }}</span>
                    <el-button style="float: right;" type="primary" @click="centerDialogVisible = true">立即购买</el-button>
                </div>
            </div>
        </div>
        <div class="div-shopdetails-middle">
            <div class="div-shopdetails-middle-top">
                <span>相关商品</span>
            </div>
            <div class="div-shopdetails-middle-buttom">
                <div style="width: 100%; height: 100%;">
                    <div v-if="wareData.length == 0"
                        style="width: 100%; height: 100%; text-align: center; display: flex;justify-content: center;align-items: center;">
                        <el-icon size="50px">
                            <MostlyCloudy />
                        </el-icon>无相关商品
                    </div>
                    <el-space v-else wrap style="margin-bottom: 2%; margin-top: 2%;">
                        <el-card v-for="i in wareData" :key="i" class="box-cardshop">
                            <div style="width: 100%; height: 100%;" @click="goToshopdetails(i.waresId)">
                                <div class="card-listshop" style="height: 70%;">
                                    <el-image style="height: 100%; width: 100%;" :src="i.waresPhoto" />
                                </div>
                                <div style="height: 15%; margin-left: 10px;">
                                    <span class="component">{{ i.waresName }}</span>
                                </div>
                                <div style="height: 15%; margin-left: 10px; font-weight: bold; color: #FFD700;">
                                    <span>¥ {{ i.waresPrice }}</span>
                                </div>
                            </div>
                        </el-card>
                    </el-space>
                </div>
            </div>
        </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="是否购买" width="500" center>
        <div>
            <span>
                商品名称：{{ waresFrom.waresName }}
            </span>
        </div>
        <div>
            <span>
                商品价格：{{ waresFrom.waresPrice }}
            </span>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="goToBuy()">
                    确认购买
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import waresApi from '@/api/wares';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { ElImage, ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import userApi from '@/api/user';
export default {
    setup() {
        const waresId = ref(null);
        const router = useRouter();
        const wareData = reactive([]);
        const waresFrom = reactive({
            waresId: null,
            waresName: '',
            waresDetails: '',
            waresPhoto: '',
            waresPrice: 0,
            isTrueWares: '',
            createTime: '',
            updateTime: '',
            userId: null,
            gameId: null,
            waresGroup: ''
        })
        const centerDialogVisible = ref(false);
        const user = JSON.parse(localStorage.getItem('user'));
        //分页查询数据填写
        const params = reactive({
            gameId: 0,
            waresGroup: '',
            waresId: 0,
        })

        //判断用户余额是否足够
        const isenough = reactive({
            userId: null,
            waresPrice: waresFrom.waresPrice,
        })

        //进行购买操作值
        const waresParams = reactive({
            userId: null,
            waresId: waresFrom.waresId,
            waresPrice: waresFrom.waresPrice
        })
        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            waresApi.getById(waresId.value).then((res) => {
                if (res.code == 200) {
                    waresFrom.waresId = res.data.waresId;
                    waresFrom.waresName = res.data.waresName;
                    waresFrom.waresDetails = res.data.waresDetails;
                    waresFrom.waresPhoto = res.data.waresPhoto;
                    waresFrom.waresPrice = res.data.waresPrice;
                    waresFrom.isTrueWares = res.data.isTrueWares;
                    waresFrom.createTime = res.data.createTime;
                    waresFrom.updateTime = res.data.updateTime;
                    waresFrom.userId = res.data.userId;
                    waresFrom.gameId = res.data.gameId;
                    waresFrom.waresGroup = res.data.waresGroup;
                    isenough.waresPrice = res.data.waresPrice;
                    waresParams.waresId = res.data.waresId;
                    waresParams.waresPrice = res.data.waresPrice;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //确认购买的方法
        const goToBuy = () => {
            if(user == null){
                router.push({ name: "home"});
                ElMessage.error("未登录无法购买，请登录后重试！")
                return;
            }else{
                isenough.userId = user.userId;
            }
            //1.余额足够直接调用api扣除余额
            userApi.isEnough(isenough).then((res) => {
                if(res.code == 200){
                    waresParams.userId = user.userId;
                    //2.余额足够进行减扣余额并且更改商品状态
                    waresApi.UpWares(waresParams).then((ress) =>{
                        if(ress.code == 200){
                            //购买成功，进行相关操作
                            ElMessage.success("购买成功")
                            //关闭弹窗
                            centerDialogVisible.value = false;
                            router.push({ path: '/shop'});
                        }else{
                            //购买失败，跳转至购物页面
                            ElMessage.error("购买失败")
                            router.push({ path: '/shop'});
                        }
                    })
                }else{
                    //2.余额不够跳转至充值页面
                    ElMessage.error("余额不足，请充值！")
                    //关闭弹窗
                    centerDialogVisible.value = false;
                    router.push({ path: '/wallet'});
                }
            })
            

        }

        //等待第一个请求结束后发送第二个请求，获取相关商品信息
        const fetchData2 = async () => {
            // 模拟异步操作
            waresApi.getWareRelation(waresId.value).then((res) => {
                if (res.code == 200) {
                    wareData.splice(0, wareData.length);
                    wareData.push(...res.data.slice(0, 10));
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //根据商品id跳转至商品详情页面
        const goToshopdetails = (waresIds) => {
            router.push({ path: '/shopdetails', query: { waresId: waresIds } });
            if (waresIds != null) {
                waresId.value = waresIds;
            } else {
                waresId.value = 0;
            }
            fetchData();
            fetchData2();
        }

        
        // 初始化数据，获取表格数据
        onMounted(() => {
            if (router.currentRoute.value.query.waresId != null) {
                waresId.value = router.currentRoute.value.query.waresId;
            } else {
                waresId.value = 0;
            }
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
            fetchData2();
        });
        return {
            fetchData,
            waresId,
            waresFrom,
            params,
            fetchData2,
            wareData,
            goToshopdetails,
            goToBuy,
            centerDialogVisible,
            isenough,
            waresParams,
        }
    }
}
</script>

<style>
.div-shopdetails-main {
    width: 60%;
    height: 700px;
    margin: auto;
    margin-top: 60px;
}
.component {
  /* 默认的文字样式 */
  color: black;
}
.component:hover {
  /* 鼠标悬浮时的文字样式 */
  color: rgb(255, 215, 0); /* 可以根据需要更改颜色 */
  cursor: pointer;
}

.div-shopdetails-top {
    width: 100%;
    height: 30%;
    margin-bottom: 2%;
    background: url(../../../static/detail-header-bg.jpg);
    display: flex;
}

.div-shopdetails-middle {
    width: 100%;
    height: 67%;
}

.div-shopdetails-top-iamge {
    width: 30%;
    height: 90%;
    padding: 10px;
}

.div-shopdetails-top-des {
    width: 65%;
    height: 100%;
}

.div-shopdetails-top-des-item1 {
    height: 20%;
    width: 100%;
    margin-left: 20px;
    color: #fafafa;
}

.div-shopdetails-top-des-item2 {
    height: 20%;
    width: 100%;
    margin-left: 22px;
    color: rgb(184, 186, 187);
    font-size: 12px;
}

.div-shopdetails-top-des-item3 {
    height: 20%;
    width: 100%;
    margin-left: 20px;
    color: rgb(184, 186, 187);
    font-size: 16px;
}

.div-shopdetails-top-des-item4 {
    height: 20%;
    width: 100%;
    margin-left: 20px;
    color: rgb(184, 186, 187);
    font-size: 16px;
}

.div-shopdetails-middle-top {
    height: 10%;
    width: 100%;
    background: url(../../../static/marcket-header-bg.jpg);
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fafafa;
}

.div-shopdetails-middle-buttom {
    height: 90%;
    width: 100%;
    background-color: #fafafa;
}

.box-cardshop {
    width: 185px;
    height: 180px;
    margin-left: 30px;
    padding: 0;
}

.card-listshop {
    width: 185px !important;
}
</style>