import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/complaint/" + data);
  },
  getListUser() {
    return http.get("/complaint");
  },
  save(data) {
    return http.post("/complaint", data);
  },
  addComplaint(data) {
    return http.post("/complaint/addComplaint", data);
  },
  delete(data) {
    return http.delete("/complaint/"+data);
  },
  getPage(params) {
    return http.get("/complaint/page", { params });
  },
};
