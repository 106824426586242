<template>
    <div class="div-bg-error">
        <div class="div-bg-error-main">
            <img style="width: 800px; height: 500px;" src="../../static/404.jpg" />
            
        </div>
        <div class="div-bg-error-butonm">
            <el-button type="primary" @click="goToHome"><el-icon><Back /></el-icon>返回首页</el-button>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
    setup() {
        const router = useRouter();
        //返回主页方法
        const goToHome = () =>{
            router.push({ name: 'home' })
        }
        return {
            goToHome,
        }
    }
}

</script>

<style>
.div-bg-error {
    width: 200vh;
    height: 100vh;
    background-color: aliceblue;
    /* 将背景图片居中显示 */
    position: fixed;
    /* 固定位置，这样它会占据整个屏幕，即使页面滚动也不会移动 */
    z-index: 9999;
    /* 设置较高的 z-index 值，确保元素位于其他内容之上 */
}

.div-bg-error-main {
    height: 70%;
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
}
.div-bg-error-butonm {
    height: 30%;
    display: flex;
    justify-content: center;
}
</style>