import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/game/" + data);
  },
  getListUser() {
    return http.get("/game");
  },
  save(data) {
    return http.post("/game", data);
  },
  delete(data) {
    return http.delete("/game/"+data);
  },
  getPage(params) {
    return http.get("/game/page", { params });
  },
  getPage2(params) {
    return http.get("/game/page2", { params });
  },
  getHotGame() {
    return http.get("/game/getHotGame");
  },
  getGroups() {
    return http.get("/game/getGroups");
  }
};
