<template>
    <el-menu style="height: 100%; overflow: hidden;" background-color="#545c64" text-color="#fff"
         active-text-color="#ffd04b" default-active="1" router>
        <div style="height: 60px;line-height: 60px;text-align: center">
            <b style="color: azure;vertical-align: middle;">系统管理</b>
        </div>
        <el-menu-item index="1" :route="{ name: 'syshome' }">
            <template #title>
                <el-icon><House /></el-icon>
                <span>主页</span>
            </template>
        </el-menu-item>
        <el-sub-menu index="2">
            <template #title>
                <el-icon><User /></el-icon>
                <span>用户管理</span>
            </template>
            <el-menu-item index="2-1" :route="{ name: 'sysuser' }">
                <template #title>
                    <el-icon><User /></el-icon>
                    <span >用户信息管理</span>
                </template>
            </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
            <template #title>
                <el-icon><Star /></el-icon>
                <span>游戏管理</span>
            </template>
            <el-menu-item index="3-1" :route="{ name: 'gameinfo' }">
                <template #title>
                    <el-icon><Tickets /></el-icon>
                    <span>游戏信息管理</span>
                </template>
            </el-menu-item>
            <el-menu-item index="3-2" :route="{ name: 'gamegroup' }">
                <template #title>
                    <el-icon><Postcard /></el-icon>
                    <span>游戏分类管理</span>
                </template>
            </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4">
            <template #title>
                <el-icon><GoodsFilled /></el-icon>
                <span>订单管理</span>
            </template>
            <el-menu-item index="4-1" :route="{ name: 'commoditygoods' }">
                <template #title>
                    <el-icon><Goods /></el-icon>
                    <span>商品信息管理</span>
                </template>
            </el-menu-item>
            <el-menu-item index="4-2" :route="{ name: 'infogoods' }">
                <template #title>
                    <el-icon><SoldOut /></el-icon>
                    <span>订单信息管理</span>
                </template>
            </el-menu-item>
            <el-menu-item index="4-3" :route="{ name: 'complaintgoods' }">
                <template #title>
                    <el-icon><PhoneFilled /></el-icon>
                    <span>投诉订单信息管理</span>
                </template>
            </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5">
            <template #title>
                <el-icon><Setting /></el-icon>
                <span>系统管理</span>
            </template>
            <el-menu-item index="5-1" :route="{ name: 'sysphoto' }">
                <template #title>
                    <el-icon><Picture /></el-icon>
                    <span >系统首页宣传图管理</span>
                </template>
            </el-menu-item>
            <el-menu-item index="5-2" :route="{ name: 'news' }">
                <template #title>
                    <el-icon><Memo /></el-icon>
                    <span >游戏资讯管理</span>
                </template>
            </el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>
  
<script>
export default {
    name: "Aside",
}
</script>
  
<style>
</style>