<template>
    <el-container style="min-height: 100vh">
        <el-aside :width="side_With + 'px'" style="background-color: rgb(238, 241, 246);box-shadow: 2px 0 6px #888888">
            <Aside />
        </el-aside>
        <el-container class="parent-container">
            <Header />
            <el-main>
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import { ref, reactive } from 'vue';
import Aside from '@/components/Aside';
import Header from "@/components/Header";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElImage, ElMessage } from 'element-plus';
export default {
    components: {
        Aside,
        Header
    },
    setup() {
        const isCollapse = ref(false);
        const logoTextShow = ref(false);
        const side_With = ref('250');
        const collapseBtnClass = ref('el-icon-s-fold');
        const router = useRouter();

        const collapse = async () => {
            isCollapse.value = !isCollapse.value;
            if (isCollapse.value) {
                side_With.value = 64
                collapseBtnClass.value = 'el-icon-s-unfold'
                logoTextShow.value = false
            } else {
                side_With.value = 200
                collapseBtnClass.value = 'el-icon-s-fold'
                logoTextShow.value = true
            }
        };
        onMounted(() => {
        })
        return {
            isCollapse,
            logoTextShow,
            side_With,
            collapseBtnClass,
            collapse
        };
    },

};
</script>

<style>
.parent-container {
    height: 100vh;
    /* 使容器占满视口高度 */
    display: flex;
    /* 虽然Element UI内部会处理，但显式声明通常是个好习惯 */
    flex-direction: column !important;
    /* 子元素垂直排列 */
}
</style>