import http from "@/utils/request";

export default {
  // getPage(params) {
  //     return http.get("/user/page", { params });
  // },
  // save(data) {
  //     return http.post("/user/", data);
  // },
  // delete(data) {
  //     return http.delete("/user/" + data);
  // },
  login(data) {
    return http.post("/user/login", data);
  },
  logout(data){
    return http.post("/user/logout", data);
  },
  getById(data) {
    return http.get("/user/" + data);
  },
  getById2(data) {
    return http.get("/user/set/" + data);
  },
  getListUser() {
    return http.get("/user");
  },
  sendSMS(data){
    return http.post("/user/sms", data)
  },
  checkSms(data){
    return http.post("/user/checkSms", data)
  },
  setPassword(data){
    return http.post("/user/setPassword", data)
  },
  smsUpdate(data){
    return http.post("/user/smsUpdate", data)
  },
  updateTel(data){
    return http.post("/user/updateTel", data)
  },
  updateTelS(data){
    return http.post("/user/updateTelS", data)
  },
  setUserIdCard(data){
    return http.post("/user/setUserIdCard", data)
  },
  save(data) {
    return http.post("/user", data);
  },
  register(data) {
    return http.post("/user/register", data);
  },
  saveAdds(data) {
    return http.post("/user/addUserBalance", data);
  },
  delete(data) {
    return http.delete("/user/" + data);
  },
  getPage(params) {
    return http.get("/user/page", { params });
  },
  isEnough(params) {
    return http.get("/user/isEnough", { params });
  },
  upDataPassword(data){
    return http.post("/user/upDataPassword", data);
  },
  withdrawal(data){
    return http.post("/user/withdrawal", data);
  }

};
