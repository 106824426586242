import axios from "axios";
import { ElMessage } from 'element-plus';
import router from "@/router";

const request = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://" + location.hostname + ":9090/api"
      : "/api", // 注意！！ 这里是全局统一加上了 后端接口前缀 前缀，后端必须进行跨域配置！
  timeout: 5000,
});


// 一小时
let OneHour = 3600000;
// let OneHour = 5000;
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      // FormData 通常用于文件上传，不需要显式设置 Content-Type
      config.headers["Content-Type"] = "multipart/form-data";
    } else if (config.data && typeof config.data === "object") {
      // 对于其他对象类型的数据，设置 Content-Type 为 application/json
      config.headers["Content-Type"] = "application/json;charset=utf-8";
    }

    let user = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")): null;
    if (user) {
      config.headers["token"] = user.token; // 设置请求头
      config.headers["startTime"] = user.startTime;
      if (user) {
        let date = new Date().getTime();
        // 如果大于就是过期了
        if (date - user.startTime > OneHour) {
          router.push({
            name: "home",
          });
          localStorage.removeItem("user");
          window.location.reload();
          ElMessage.error("登录失效，请重新登录");
        } else {
          user.startTime = date;
          localStorage.setItem("user", JSON.stringify(user));
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    // 如果是返回的文件
    if (response.config.responseType === "blob") {
      return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === "string") {
      res = res ? JSON.parse(res) : res;
    }
    //当权限验证不通过时给出提示
    if (res.code === "401") {
      ElementPlus.Message({
        message: res.msg,
        type: "error",
      });
    }
    if (res.code === "402") {
      //当要重新登陆的时候跳转到登录页面
      router.push({
        name: "home",
      });
    }
    return res;
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default request;
