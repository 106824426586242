import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/photo/" + data);
  },
  getListUser() {
    return http.get("/photo");
  },
  save(data) {
    return http.post("/photo", data);
  },
  delete(data) {
    return http.delete("/photo/"+data);
  },
  getPage(params) {
    return http.get("/photo/page", { params });
  },
};
