<template>
    <div id="app">
        <!-- 新增记录按钮 -->
        <el-button @click="addRecord">新增</el-button>
        <el-button type="primary" style="float: right;" @click="fetchData">搜索</el-button>
        <el-input style="width: 200px; cursor: pointer; float: right;" suffix-icon="el-icon-search" placeholder="请输入分类名称"
            v-model="params.categoryName"></el-input>
        <!-- 用户表格 -->
        <el-table :data="tableData" style="width: 100%">
            <!-- 表格列定义 -->
            <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
            <el-table-column prop="categoryName" label="名称" width="180" align="center"></el-table-column>
            <el-table-column prop="categoryDetails" label="简介" width="700" align="center"></el-table-column>
            <el-table-column label="是否停用" align="center">
                <template #default="scope">
                    <el-tag v-if="scope.row.isTrue == 0" type="success">正常</el-tag>
                    <el-tag v-else type="danger">已停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="180" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button @click="editRecord(scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" @click="deleteRecord(scope.row.categoryId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="totalItems">
        </el-pagination>

        <!-- 编辑/新增对话框 -->
        <el-dialog v-model="dialogVisible" title="新增/编辑记录">
            <el-form :model="form" :rules="rules" label-width="120px">
                <el-form-item label="名称" prop="categoryName">
                    <el-input v-model="form.categoryName"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="categoryDetails">
                    <el-input  v-model="form.categoryDetails" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="是否停用" prop="isTrue">
                    <el-switch v-model="form.isTrue" inline-prompt active-text="是" inactive-text="否" :active-value="1"
                        :inactive-value="0" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="save(form)">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import gamegroupApi from '@/api/gamegroup';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import { computed } from 'vue';
export default {
    setup() {
        // 表格数据
        const tableData = reactive([]);

        // 对话框可见性
        const dialogVisible = ref(false);

        const currentPage = ref(1); // 当前页码
        const pageSize = ref(10); // 每页显示的数据条数
        const totalItems = ref(0); // 数据的总条数

        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };

        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            categoryName: ''
        })

        // 表单数据
        const form = reactive({
            categoryId: null,
            categoryName: '',
            categoryDetails: '',
            createTime: '',
            updateTime: '',
            isTrue: 0,
        });

        // 表单验证规则
        const rules = {
            categoryName: [
                { required: true, message: '请输入分类名称', trigger: 'blur' },
            ],
            categoryDetails: [
                { required: true, message: '请输入分类简介', trigger: 'blur' },
            ],
            // ... 其他表单项的验证规则
        };

        // 新增记录
        const addRecord = () => {
            dialogVisible.value = true;
            form.categoryId = null,
            form.categoryName = '';
            form.categoryDetails = '';
            form.createTime = '';
            form.updateTime = '';
            form.isTrue = 0;
        };

        // 编辑记录
        const editRecord = (record) => {
            dialogVisible.value = true;
            form.categoryId = record.categoryId;
            form.categoryName = record.categoryName;
            form.categoryDetails = record.categoryDetails;
            form.createTime = record.createTime;
            form.updateTime = record.updateTime;
            form.isTrue = record.isTrue;
        };

        // 删除记录
        const deleteRecord = (id) => {
            gamegroupApi.delete(id).then(res => {
                if (res.code == 200) {
                    ElMessage.success("删除成功")
                    fetchData();
                } else {
                    ElMessage.error("删除失败")
                }
            })
        }

        const save = async (form) => {
            gamegroupApi.save(form).then(res => {
                if (res.code == 200) {
                    dialogVisible.value = false;
                    ElMessage.success("保存成功")
                    fetchData();
                } else {
                    ElMessage.error("保存失败")
                }
            })
        }

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            gamegroupApi.getPage(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
        });

        // 返回需要暴露给模板的响应式数据和方法
        return {
            tableData,
            dialogVisible,
            form,
            rules,
            addRecord,
            editRecord,
            deleteRecord,
            save,
            paginatedData,
            currentPage,
            pageSize,
            totalItems,
            handlePageSizeChange,
            handleCurrentChange,
            fetchData,
            params
        };
    },
};
</script>

<style>
/* 表格样式 */
.el-table {
    width: 100%;
    margin-bottom: 20px;
}

/* 表格列样式 */
.el-table__body-wrapper {
    overflow-x: auto;
}

.el-table__body tr td {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* 对话框样式 */
.el-dialog {
    width: 50%;
    /* 或者你可以设置具体的像素值 */
}

/* 对话框内容样式 */
.el-dialog__body {
    padding: 20px;
}

/* 对话框底部按钮样式 */
.el-dialog__footer {
    text-align: right;
    padding: 10px 20px;
    border-top: 1px solid #eee;
}

/* 其他样式 */
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

.el-button {
    margin-left: 10px;
}
</style>