<template>
    <div id="app">
        <!-- 新增记录按钮 -->
        <el-button @click="addRecord">新增</el-button>
        <el-button type="primary" style="float: right;" @click="fetchData">搜索</el-button>
        <el-input style="width: 200px; cursor: pointer; float: right;" suffix-icon="el-icon-search"
            placeholder="请输入资讯主题" v-model="params.articleTheme"></el-input>
        <!-- 用户表格 -->
        <el-table :data="tableData" style="width: 100%">
            <!-- 表格列定义 -->
            <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
            <el-table-column prop="articleTheme" label="主题" width="180" align="center"></el-table-column>
            <el-table-column label="内容" width="200" align="center">
                <template #default="scope">
                    <el-button @click="showContent(scope.row.articleContent)">查看内容</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="发布人" width="200" align="center"></el-table-column>
            <el-table-column label="封面" width="200" align="center">
                <template #default="scope">
                    <img v-if="scope.row.articleUrl" :src="scope.row.articleUrl"
                        style="height: 30px; width: 30px; border-radius: 50%" />
                    <span v-else>暂无</span>
                </template>
            </el-table-column>
            <el-table-column prop="articleNumber" label="热度" width="180" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button @click="editRecord(scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" @click="deleteRecord(scope.row.articleId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="totalItems">
        </el-pagination>

        <!-- 编辑/新增对话框 -->
        <el-dialog v-model="dialogVisible" title="新增/编辑记录" @close="closeDialog" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" label-width="120px">
                <el-form-item label="主题" prop="photoDes">
                    <el-input v-model="form.articleTheme"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="photoLevel">
                    <div id="editor"></div>
                </el-form-item>
                <el-form-item label="封面">
                    <el-upload class="upload-demo" ref="upload" action="#" :show-file-list="false"
                        :on-change="handleChange" :auto-upload="false" list-type="picture-card">
                        <img v-if="form.articleUrl" :src="form.articleUrl" class="avatar" width="88" height="88">
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item label="热度" prop="photoLevel">
                    <el-input v-model="form.articleNumber"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="save(form)">确定</el-button>
            </span>
        </el-dialog>
        <!-- 展示文本内容弹窗 -->
        <el-dialog v-model="dialogVisible1" title="文本内容">
            <div class="w-e-text">
                <div v-html="showcontent"></div>
            </div>
            <el-button type="primary" @click="dialogVisible1 = false">确定</el-button>
        </el-dialog>
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import fileApi from '@/api/file';
import articleApi from '@/api/article';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { nextTick } from 'vue'
import E from 'wangeditor'
import hljs from 'highlight.js';

export default {
    setup() {
        // 表格数据
        const tableData = reactive([]);

        // 对话框可见性
        const dialogVisible = ref(false);

        const currentPage = ref(1); // 当前页码
        const pageSize = ref(10); // 每页显示的数据条数
        const totalItems = ref(0); // 数据的总条数

        //设置分类值
        const categoryIds = ref([]);

        //定义文件类型
        const file = ref(null);
        const editor = ref(null);
        const showcontent = ref(null);
         // 对话框可见性
        const dialogVisible1 = ref(false);

        //定义文件类型
        const phtotoUrl = ref('');

        const user = JSON.parse(localStorage.getItem('user'));

        // 上传头像前的处理函数
        const handleChange = function (rawFile) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
            if (!allowedTypes.includes(rawFile.raw.type)) {
                ElMessage.error('Avatar picture must be JPG format!');
                return false;
            } else if (rawFile.raw.size / 1024 / 1024 > 2) {
                ElMessage.error('Avatar picture size can not exceed 2MB!');
                return false;
            }
            form.articleUrl = rawFile.url;
            file.value = rawFile
            return true;
        }

        //销毁事件
        const closeDialog = () => {
            editor.value.destroy();
            editor.value = null;
            form.articleUrl = '';
        };

        //展示文本内容
        const showContent = (articleContent) =>{
            showcontent.value = articleContent;
            dialogVisible1.value = true;

        };
        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };

        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            articleTheme: ''
        })

        // 表单数据
        const form = reactive({
            articleId: null,
            articleTheme: '',
            articleContent: '',
            createTime: '',
            articleUrl: '',
            articleNumber: 0,
            articleUserId: null,
        });

        // 表单验证规则
        const rules = {
            articleTheme: [
                { required: true, message: '请输入主题', trigger: 'blur' },
            ],
            articleContent: [
                { required: true, message: '请输入内容', trigger: 'blur' },
            ],
            // ... 其他表单项的验证规则
        };

        const setTextconfig = () =>{
            nextTick(() => {
                // 在这里，DOM 已经更新
                editor.value = new E('#editor')
                editor.value.height = hljs;
                editor.value.config.uploadImgServer = 'http://127.0.0.1:9090/api/oss/editor/upload';
                editor.value.config.uploadImgHeaders = {
                    token : user.token
                }
                editor.value.config.uploadImgParams = {
                    type : 'img'
                }
                editor.value.config.uploadFileName = 'file';

                editor.value.config.uploadVideoServer = 'http://127.0.0.1:9090/api/oss/editor/upload';
                editor.value.config.uploadVideoHeaders = {
                    token : user.token
                }
                editor.value.config.uploadVideoParams = {
                    type : 'video'
                }
                editor.value.config.uploadVideoName = 'file';

                editor.value.create()
            })
        }
        // 新增记录
        const addRecord = () => {
            dialogVisible.value = true;
            setTextconfig();
            form.articleId = null,
            form.articleTheme = '';
            form.articleContent = '';
            form.createTime = '';
            form.articleNumber = 0;
            phtotoUrl.value = '';
            form.articleUserId = user.userId;
        };

        // 编辑记录
        const editRecord = (record) => {
            dialogVisible.value = true;
            setTextconfig();
            setTimeout(() => {
                editor.value.txt.html(record.articleContent)
            }, 0);
            form.articleId = record.articleId;
            form.articleTheme = record.articleTheme;
            form.createTime = record.createTime;
            form.articleNumber = record.articleNumber;
            form.articleUrl = record.articleUrl;
            phtotoUrl.value = record.articleUrl;
        };

        // 删除记录
        const deleteRecord = (id) => {
            articleApi.delete(id).then(res => {
                if (res.code == 200) {
                    ElMessage.success("删除成功")
                    fetchData();
                } else {
                    ElMessage.error("删除失败")
                }
            })
        }

        const save = async (form) => {
            //文件等于null说明这次没有上传文件
            if (file.value != null) {
                console.log(file.value.raw)
                let formData = new FormData();
                formData.append('file', file.value.raw);
                fileApi.uploadFile(formData).then(ress => {
                    if (ress.code == 200) {
                        //获取图片的内容
                        form.articleUrl = ress.data;
                        //获取编辑框的内容
                        let content = editor.value.txt.html();
                        form.articleContent = content;
                        articleApi.save(form).then(res => {
                            if (res.code == 200) {
                                dialogVisible.value = false;
                                ElMessage.success("保存成功")
                                form.articleUrl = ''
                                fetchData();
                            } else {
                                ElMessage.error("保存失败")
                            }
                        })
                    } else {
                        ElMessage.error("上传失败")
                    }
                })
            } else {
                form.articleUrl = phtotoUrl.value;
                //获取编辑框的内容
                let content = editor.value.txt.html();
                form.articleContent = content;
                articleApi.save(form).then(res => {
                    if (res.code == 200) {
                        dialogVisible.value = false;
                        ElMessage.success("保存成功")
                        fetchData();
                    } else {
                        ElMessage.error("保存失败")
                    }
                })
            }
        }

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            articleApi.getPage2(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
        });

        // 返回需要暴露给模板的响应式数据和方法
        return {
            tableData,
            dialogVisible,
            form,
            rules,
            addRecord,
            editRecord,
            deleteRecord,
            save,
            paginatedData,
            currentPage,
            pageSize,
            totalItems,
            handlePageSizeChange,
            handleCurrentChange,
            categoryIds,
            fetchData,
            params,
            handleChange,
            file,
            phtotoUrl,
            editor,
            closeDialog,
            showcontent,
            dialogVisible1,
            showContent,
            user
            
        };
    },
};
</script>

<style>
/* 表格样式 */
.el-table {
    width: 100%;
    margin-bottom: 20px;
}

/* 表格列样式 */
.el-table__body-wrapper {
    overflow-x: auto;
}

.el-table__body tr td {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* 对话框样式 */
.el-dialog {
    width: 50%;
    /* 或者你可以设置具体的像素值 */
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}


/* 对话框内容样式 */
.el-dialog__body {
    padding: 20px;
}

/* 对话框底部按钮样式 */
.el-dialog__footer {
    text-align: right;
    padding: 10px 20px;
    border-top: 1px solid #eee;
}

/* 其他样式 */
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

.el-button {
    margin-left: 10px;
}
</style>