<template>
    <div class="common-layout" style="width: 100%; height: 100%;">
        <el-container>
            <!-- 顶部菜单栏部分 -->
            <el-header class="white-text"
                style="width: auto; height: auto; margin-left: 0; margin-right: 0; padding: 0px; color: aliceblue;">
                <el-menu class="el-menu-transparent" :default-active="activeIndex" text-color="#EEEEEE"
                    active-text-color="#FFFFFF" mode="horizontal" :ellipsis="false" style="width: 60%; margin: auto;"
                    router>
                    <div class="logo" @click="goToHome"></div>
                    <!-- <el-menu-item  class="logo"></el-menu-item> -->
                    <el-menu-item index="/showing" style="margin-left: 5px;">首页</el-menu-item>
                    <el-menu-item index="/gameclassify">游戏</el-menu-item>
                    <el-menu-item index="/shop">游戏市场</el-menu-item>
                    <el-menu-item index="/information">游戏资讯</el-menu-item>
                    <div class="flex-grow" />
                    <template v-if="islogin">
                        <div style="margin-right: 25px;">
                            <el-avatar style="margin-top: 3px; width: 50px; height: 50px;" v-if="state.squareUrl"
                                fit="fill" :src="state.squareUrl" />
                            <el-avatar style="margin-top: 3px; width: 50px; height: 50px;" v-else :src="state.circleUrl"
                                fit="fill" />
                        </div>
                        <el-menu-item index="/personal">个人中心</el-menu-item>
                        <template v-if="isloginadmin">
                            <el-menu-item index="/management">后台管理</el-menu-item>
                        </template>
                        <el-menu-item @click="logOut">退出登录</el-menu-item>
                    </template>
                    <template v-else>
                        <el-menu-item index @click="loginDialogVisible = true">
                            登录/注册
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-header>
            <!-- 中间窗口部分 -->
            <el-main style="width: auto; height: auto;  margin-left: 0; margin-right: 0; padding: 0px;">
                <router-view />
                <div> </div>
                <div class="weibu">
                    <el-row>
                        <el-col :span="6">
                            <h3>新手教程</h3>
                            <p>如何进行交易</p>
                            <p>交易安全怎么保证</p>
                            <p>如何充值</p>
                            <p>如何修改密码</p>
                            <p>如何查看余额</p>
                        </el-col>
                        <el-col :span="6">
                            <h3>便捷支付</h3>
                            <p>支付宝支付</p>
                            <p>微信支付</p>
                            <p>银行卡支付</p>
                        </el-col>
                        <el-col :span="6">
                            <h3>热门游戏</h3>
                            <ul>
                                <li><a href="https://www.counter-strike.net/cs2">CS2</a>
                                </li>
                                <li><a href="https://pvp.qq.com/">王者荣耀</a>
                                </li>
                                <li><a href="https://val.qq.com/">无畏契约</a>
                                </li>
                                <li><a href="https://ys.mihoyo.com/">原神</a></li>
                                <li><a href="https://jcc.qq.com/">金铲铲之战</a></li>
                            </ul>
                        </el-col>
                        <el-col :span="6">
                            <h3>联系我们</h3>
                            <img src="../../static/联系我们1.jpg" alt="" style="width: 152px;">
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <!-- 底部介绍部分 -->

        </el-container>
        <!-- 用户进行登录的对话框 -->
        <el-dialog v-model="loginDialogVisible" class="my-special-dialog" align-center>
            <div style="display: flex; width: 100%; height: 100%;">
                <div style="width: 40%; height: 550px;">
                    <img style="width: 100%; height: 100%; border-radius: 20px 0 0 20px;"
                        src="../../static/login-lift.jpg" />
                </div>
                <div style="width: 60%; height: 550px">
                    <div style="height: 400px">
                        <!-- 账号登录内容 -->
                        <div class="login-dialog" v-if="dialogIndex == 0">
                            <div class="login-dialog-mian">
                                <div class="center-span">
                                    <span>账号登录</span>
                                </div>
                                <div style="margin-top: 30px; width: 100%;">
                                    <el-form :model="loginFrom" style="width: 100%;">
                                        <div class="top-input">
                                            <el-input v-model="loginFrom.userAccount"
                                                style="width: 350px; height: 40px;" placeholder="请输入账号/手机号"
                                                @keyup.enter.native="keyDown(1)"></el-input>
                                        </div>
                                        <div class="top-input">
                                            <el-input type="password" v-model="inss" style="width: 350px; height: 40px;"
                                                placeholder="请输入密码" @keyup.enter.native="keyDown(1)"></el-input>
                                        </div>
                                    </el-form>
                                    <div class="top-inputwjmm">
                                        <div style="width: 100%;" @click="dialogIndex = 3">
                                            <span class="componentlogin"
                                                style="float: right; margin-right: 50px;">忘记密码</span>
                                        </div>
                                    </div>
                                    <div class="top-input">
                                        <el-button type="primary"
                                            style="width: 350px; height: 40px;border-radius: 10px; margin-left: -3px;"
                                            @click="homeLogin(loginFrom)">立即登录</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 注册内容 -->
                        <div class="register-dialog" v-if="dialogIndex == 1">
                            <div class="register-dialog-mian">
                                <div class="center-span">
                                    <span>账号注册</span>
                                </div>
                                <div style="margin-top: 30px; width: 100%;">
                                    <el-form :model="form" :rules="rules" style="width: 100%;">
                                        <div class="top-input-register-url">
                                            <el-form-item prop="gamePhoto">
                                                <el-upload class="upload-demo" ref="upload" action="#"
                                                    :show-file-list="false" :on-change="handleChange"
                                                    :auto-upload="false" list-type="picture-card">
                                                    <img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatar"
                                                        width="88" height="88">
                                                    <el-icon v-else class="avatar-uploader-iconsss">
                                                        <Plus />
                                                    </el-icon>
                                                </el-upload>
                                            </el-form-item>
                                        </div>
                                        <div class="top-input-register">
                                            <el-form-item prop="nickName">
                                                <el-input v-model="form.nickName" style="width: 350px; height: 30px;"
                                                    placeholder="请输入昵称" @keyup.enter.native="keyDown(2)"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="top-input-register">
                                            <el-form-item prop="userAccount">
                                                <el-input v-model="form.userAccount" style="width: 350px; height: 30px;"
                                                    placeholder="请输入账号" @keyup.enter.native="keyDown(2)"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="top-input-register">
                                            <el-form-item prop="userPasswords">
                                                <el-input type="password" v-model="form.userPasswords"
                                                    style="width: 350px; height: 30px;" placeholder="请输入密码"
                                                    @keyup.enter.native="keyDown(2)"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="top-input-register">
                                            <el-form-item prop="userTel">
                                                <el-input v-model="form.userTel" style="width: 350px; height: 30px;"
                                                    placeholder="请输入手机号" @keyup.enter.native="keyDown(2)"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="top-input-register">
                                            <el-form-item prop="userIdCard">
                                                <el-input v-model="form.userIdCard" style="width: 350px; height: 30px;"
                                                    placeholder="请输入身份证号" @keyup.enter.native="keyDown(2)"></el-input>
                                            </el-form-item>
                                        </div>

                                    </el-form>
                                    <div class="top-input-register">
                                        <el-button type="primary"
                                            style="width: 350px; height: 40px;border-radius: 10px; margin-left: -3px;"
                                            @click="registerAdd()">立即注册</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 手机号登录内容 -->
                        <div v-if="dialogIndex == 2">
                            <div class="tel-dialog-mian">
                                <div class="center-span">
                                    <span>手机号登录</span>
                                </div>
                                <div style="margin-top: 30px; width: 100%;">
                                    <el-form :model="loginFrom" style="width: 100%;">
                                        <div class="top-input">
                                            <el-input v-model="loginFrom.userTel" style="width: 350px; height: 40px;"
                                                placeholder="请输入手机号"></el-input>
                                        </div>
                                        <div class="top-input">
                                            <el-input v-model="loginFrom.smsCode" style="width: 250px; height: 40px;"
                                                placeholder="请输入验证码"></el-input>
                                            <el-button v-if="!sms.disabled"
                                                style="width: 100px; height: 40px;border-radius: 10px; margin-left: 3px;"
                                                @click="sendSMS(loginFrom)">获取验证码</el-button>
                                            <el-button v-else disabled
                                                style="width: 100px; height: 40px;border-radius: 10px; margin-left: 3px;">{{
                    sms.count }}秒后重新获取</el-button>
                                        </div>
                                    </el-form>
                                    <div class="top-input">
                                        <el-button type="primary"
                                            style="width: 350px; height: 40px;border-radius: 10px; margin-left: -3px;"
                                            @click="homeLogintel(loginFrom)">立即登录</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 忘记密码进行手机验证内容框 -->
                        <div v-if="dialogIndex == 3">
                            <div class="tel-dialog-mian">
                                <div class="center-span">
                                    <span>忘记密码</span>
                                </div>
                                <div style="margin-top: 30px; width: 100%;">
                                    <el-form :model="loginFrom" style="width: 100%;">
                                        <div class="top-input">
                                            <el-input v-model="loginFrom.userTel" style="width: 350px; height: 40px;"
                                                placeholder="请输入手机号"></el-input>
                                        </div>
                                        <div class="top-input">
                                            <el-input v-model="loginFrom.smsCode" style="width: 250px; height: 40px;"
                                                placeholder="请输入验证码"></el-input>
                                            <el-button v-if="!sms.disabled"
                                                style="width: 100px; height: 40px;border-radius: 10px; margin-left: 3px;"
                                                @click="sendSMS(loginFrom)">获取验证码</el-button>
                                            <el-button v-else disabled
                                                style="width: 100px; height: 40px;border-radius: 10px; margin-left: 3px;">{{
                    sms.count }}秒后重新获取</el-button>
                                        </div>
                                    </el-form>
                                    <div class="top-input">
                                        <el-button type="primary"
                                            style="width: 350px; height: 40px;border-radius: 10px; margin-left: -3px;"
                                            @click="checkSms(loginFrom)">立即找回</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 忘记密码进行重置密码内容 -->
                        <div v-if="dialogIndex == 4">
                            <div class="tel-dialog-mian">
                                <div class="center-span">
                                    <span>重置密码</span>
                                </div>
                                <div style="margin-top: 30px; width: 100%;">
                                    <el-form :model="updataPassword" style="width: 100%;">
                                        <div class="top-input">
                                            <el-input type="password" v-model="updataPassword.newPassword" style="width: 350px; height: 40px;"
                                                placeholder="输入新密码"></el-input>
                                        </div>
                                        <div class="top-input">
                                            <el-input type="password" v-model="updataPassword.checkNewPassword" style="width: 350px; height: 40px;"
                                                placeholder="确认新密码"></el-input>
                                        </div>
                                    </el-form>
                                    <div class="top-input">
                                        <el-button type="primary"
                                            style="width: 350px; height: 40px;border-radius: 10px; margin-left: -3px;"
                                            @click="setPassword(updataPassword)">立即重置</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 导航栏 -->
                    <div style="height: 102px;">
                        <div style="height: 70px; display: flex;">
                            <div class="butonm-card-items" @click="dialogIndex = 0">
                                <el-icon style="margin-right: 5px;">
                                    <UserFilled />
                                </el-icon><span class="componentlogin">账号登录</span>
                            </div>
                            <div class="butonm-card-items" @click="dialogIndex = 1">
                                <el-icon style="margin-right: 5px;">
                                    <Star />
                                </el-icon><span class="componentlogin">账号注册</span>
                            </div>
                            <div class="butonm-card-items" @click="dialogIndex = 2">
                                <el-icon style="margin-right: 5px;">
                                    <Iphone />
                                </el-icon><span class="componentlogin">手机号登录</span>
                            </div>
                        </div>
                        <!-- 底部勾选协议 -->
                        <div style="height:32px; margin-left: 30px">
                            <el-checkbox v-model="checked2">勾选即代表同意《网站协议》</el-checkbox>
                        </div>
                    </div>
                </div>

            </div>

        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import { ElImage, ElMessage } from 'element-plus';
import axios from 'axios';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import userApi from '@/api/user';
import fileApi from '@/api/file';
import { MD5 } from 'crypto-js';
export default {
    setup() {
        //定义响应式变量
        const loginDialogVisible = ref(false);
        const islogin = ref(false);
        const isloginadmin = ref(false);
        const dialogIndex = ref(0);
        const checked2 = ref(false);
        // 定义一个响应式对象
        const loginFrom = ref({
            userAccount: '',
            userPassword: '',
            userTel: '',
            smsCode: ''
        });
        const updataPassword = ref({
            newPassword:'',
            checkNewPassword:''
        });
        const pushUpdataPassword = ref({
            userId:null,
            newPassword:'',
            checkNewPassword:''
            
        });

        const inss = ref('');
        // 表单数据
        const form = reactive({
            userId: null,
            userAccount: '',
            userTel: '',
            userIdCard: '',
            userPassword: '',
            userPasswords: '',
            nickName: '',
            userRole: '',
            avatarUrl: ''
        });
        const file = ref(null);
        // 验证码计时器
        const sms = reactive({
            disabled: false,
            total: 60,
            count: 0
        })

        // 计时器处理器
        const timerHandler = () => {
            sms.count = sms.total
            sms.disabled = true

            let timer = setInterval(() => {
                if (sms.count > 1 && sms.count <= sms.total) {
                    sms.count--
                } else {
                    sms.disabled = false
                    clearInterval(timer)
                }
            }, 1000)
        }

        // 表单验证规则
        const rules = {
            userTel: [
                { pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, required: true, message: '请输入正确的手机号', trigger: 'blur' },
            ],
            userIdCard: [
                { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, required: true, message: '请输入正确的身份证号', trigger: 'blur' },
            ],
            userAccount: [
                { required: true, message: '请输入账号', trigger: 'blur' }, { max: 20, message: "账号长度不能超过20位" }, { min: 6, message: "账号长度不能低于6位" }
            ],
            userPasswords: [
                { required: true, message: '请输入密码', trigger: 'blur' }, { max: 18, message: "密码长度不能超过18位" }
            ],
            // ... 其他表单项的验证规则
        };

        // 上传头像前的处理函数
        const handleChange = function (rawFile) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
            if (!allowedTypes.includes(rawFile.raw.type)) {
                ElMessage.error('Avatar picture must be JPG format!');
                return false;
            } else if (rawFile.raw.size / 1024 / 1024 > 2) {
                ElMessage.error('Avatar picture size can not exceed 2MB!');
                return false;
            }
            form.avatarUrl = rawFile.url;
            file.value = rawFile
            return true;
        }

        //判断当前是否登录，如果没有登录，进入首页时进行登录弹窗
        const goToLogin = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            //当前没有这个信息时一直提示登录
            if (user == null) {
                loginDialogVisible.value = true;
            }
        }
        //进行验证找回密码验证码是否正确
        const checkSms = (loginFrom) => {
            if (loginFrom.userTel === '' || loginFrom.smsCode === '') {
                ElMessage.error("请输入手机号和验证码");
            } else {
                userApi.checkSms(loginFrom).then((res) => {
                    if (res.code == 200) {
                        dialogIndex.value = 4;
                        loginFrom.userTel = '';
                        loginFrom.smsCode = '';
                        pushUpdataPassword.userId = res.data.userId;
                    } else {
                        loginFrom.userTel = '';
                        loginFrom.smsCode = '';
                        ElMessage.error(res.msg);
                    }
                })
            }
        }
        //进行密码的修改
        const setPassword = (updataPassword) =>{
            if (updataPassword.newPassword === '' || updataPassword.checkNewPassword === '') {
                ElMessage.error("请输入密码");
            }else{
                if(updataPassword.newPassword != updataPassword.checkNewPassword){
                    ElMessage.error("两次密码输入不一致");
                    return;
                }
                console.log(updataPassword.newPassword);
                console.log(updataPassword.checkNewPassword);
                pushUpdataPassword.newPassword = MD5(updataPassword.newPassword).toString();
                pushUpdataPassword.checkNewPassword = MD5(updataPassword.checkNewPassword).toString();
                console.log(pushUpdataPassword.newPassword);
                console.log(pushUpdataPassword.checkNewPassword);
                userApi.setPassword(pushUpdataPassword).then((res) => {
                    if(res.code == 200){
                        ElMessage.success("重置密码成功！")
                        dialogIndex.value = 0;
                        updataPassword.newPassword = '';
                        updataPassword.checkNewPassword = '';
                        pushUpdataPassword.newPassword = '';
                        pushUpdataPassword.checkNewPassword = '';
                    }else{
                        updataPassword.newPassword = '';
                        updataPassword.checkNewPassword = '';
                        pushUpdataPassword.newPassword = '';
                        pushUpdataPassword.checkNewPassword = '';
                        ElMessage.error("重置密码失败！")
                    }
                })
            }
        }
        //注册方法
        const registerAdd = async () => {
            //文件等于null说明这次没有上传文件
            if (file.value != null) {
                let formData = new FormData();
                formData.append('file', file.value.raw);
                fileApi.uploadFile(formData).then(ress => {
                    if (ress.code == 200) {
                        form.avatarUrl = ress.data;
                        form.userPassword = MD5(form.userPasswords).toString();
                        form.userPasswords = MD5(form.userPasswords).toString();
                        console.log(form)
                        userApi.register(form).then(res => {
                            console.log(form)
                            if (res.code == 200) {
                                dialogIndex.value = 0;
                                file.value = null;
                                form.userAccount = '';
                                form.userTel = '';
                                form.userIdCard = '';
                                form.userPassword = '';
                                form.userPasswords = '';
                                form.nickName = '';
                                form.avatarUrl = '';
                                ElMessage.success("注册成功")
                            } else {
                                ElMessage.error(res.msg)
                            }
                        })
                    } else {
                        ElMessage.error("上传失败")
                    }
                })
            } else {
                userApi.register(form).then(res => {
                    if (res.code == 200) {
                        dialogIndex.value = 0;
                        file.value = null;
                        form.userAccount = '';
                        form.userTel = '';
                        form.userIdCard = '';
                        form.userPassword = '';
                        form.userPasswords = '';
                        form.nickName = '';
                        form.avatarUrl = '';
                        ElMessage.success("注册成功")
                    } else {
                        ElMessage.error(res.msg)
                    }
                })
            }

        }

        const state = reactive({
            circleUrl:
                'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            squareUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        })
        const router = useRouter();
        const activeIndex = ref(router.currentRoute.value.path);
        // 使用 watch 监听路由变化
        watch(
            () => router.currentRoute.value,
            (newRoute, oldRoute) => {
                activeIndex.value = newRoute.path;
            },
            { immediate: true } // 立即执行回调，用于初始化 activeIndex
        );
        onMounted(() => {
            let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
            if (user == null) {
                islogin.value = false;
            }
            isloginadmin.value = false;
            getUser();
            // 绑定监听事件
            activeIndex.value = '/showing'; // 初始激活的菜单项
            goToLogin();
        })
        onUnmounted(() => {
            // 销毁事件
        })
        const goToHome = () => {
            router.push({ path: '/showing' })
        }
        //发送手机验证码
        const sendSMS = (loginFrom) => {
            if (loginFrom.userTel == '') {
                ElMessage.error("请填写正确的手机号");
                return;
            }
            userApi.sendSMS(loginFrom).then((res) => {
                if (res.code == 200) {
                    timerHandler();
                    ElMessage.success("验证码请求成功");
                } else {
                    ElMessage.error("验证码请求失败，请重试");
                }
            })
        }
        //声明login函数
        const homeLogintel = (loginFrom) => {
            if (checked2.value == false) {
                ElMessage.error("请勾选下方协议");
                return;
            }
            if (loginFrom.userTel === '' || loginFrom.smsCode === '') {
                ElMessage.error("请输入手机号和验证码");
            } else {
                axios.post("/user/login", loginFrom).then((res) => {
                    if (res.data.code == 200) {
                        loginDialogVisible.value = false;
                        ElMessage.success("登录成功");
                        islogin.value = true;
                        if (res.data.data.userRole == "系统管理员") {
                            isloginadmin.value = true;
                        }
                        localStorage.setItem('user', JSON.stringify(res.data.data));
                        let user = JSON.parse(localStorage.getItem('user'));
                        state.squareUrl = res.data.data.avatarUrl
                        loginFrom.userTel = '';
                        loginFrom.smsCode = '';
                        checked2.value = false;
                    }
                    else {
                        loginFrom.userTel = '';
                        loginFrom.smsCode = '';
                        checked2.value = false;
                        if (res.data.code == 600) {
                            ElMessage.error(res.data.msg);
                        }
                        else {
                            ElMessage.error("登录失败");
                        }
                    }
                }).catch((err) => {
                });
            }

        };

        //声明login函数
        const homeLogin = (loginFrom) => {

            if (checked2.value == false) {
                ElMessage.error("请勾选下方协议");
                return;
            }
            loginFrom.userPassword = MD5(inss.value).toString();
            if (loginFrom.userAccount === '' || loginFrom.userPassword === '') {
                ElMessage.error("请输入用户名或密码");
            } else {
                axios.post("/user/login", loginFrom).then((res) => {
                    if (res.data.code == 200) {
                        loginDialogVisible.value = false;
                        ElMessage.success("登录成功");
                        islogin.value = true;
                        if (res.data.data.userRole == "系统管理员") {
                            isloginadmin.value = true;
                            console.log(res.data.data.userRole)
                        }
                        localStorage.setItem('user', JSON.stringify(res.data.data));
                        let user = JSON.parse(localStorage.getItem('user'));
                        state.squareUrl = res.data.data.avatarUrl
                        loginFrom.userAccount = '';
                        loginFrom.userPassword = '';
                        inss.value = '';
                        checked2.value = false;
                    }
                    else {
                        loginFrom.userAccount = '';
                        loginFrom.userPassword = '';
                        checked2.value = false;
                        inss.value = '';
                        if (res.data.code == 600) {
                            ElMessage.error(res.data.msg);
                        }
                        else {
                            ElMessage.error("登录失败");
                        }
                    }
                }).catch((err) => {
                });
            }

        };
        //每次回到这个页面判断登录状态是否存在
        const getUser = () => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user != null) {
                islogin.value = true;
                state.squareUrl = user.avatarUrl
                if (user.userRole == "系统管理员") {
                    isloginadmin.value = true;
                }
            }
        }
        // 点击回车键登录
        const keyDown = (index) => {
            if (index == 1) {
                //监听登录
                if (loginFrom.value.userAccount == '' || inss.value == '') {
                    ElMessage.error("请输入用户名或密码");
                } else {
                    homeLogin(loginFrom.value);
                }
            } else if (index == 2) {
                //监听注册
                registerAdd()
            }
        }
        //声明logout函数
        const logOut = () => {
            islogin.value = false;
            isloginadmin.value = false;
            router.push({ path: '/showing' })
            let user = JSON.parse(localStorage.getItem('user'));
            userApi.logout(user).then((res) => {
                if (res.code == 200) {
                    ElMessage.success(user.nickName + "成功退出登录");
                    localStorage.removeItem('user');
                } else {
                    ElMessage.error(user.nickName + "退出失败");
                }
            })

        };
        return {
            loginDialogVisible,
            loginFrom,
            islogin,
            isloginadmin,
            homeLogin,
            homeLogintel,
            logOut,
            state,
            keyDown,
            getUser,
            activeIndex,
            goToHome,
            form,
            rules,
            handleChange,
            file,
            registerAdd,
            goToLogin,
            inss,
            dialogIndex,
            checked2,
            sendSMS,
            timerHandler,
            sms,
            checkSms,
            updataPassword,
            pushUpdataPassword,
            setPassword
        };
    },
    components: { ElImage }
}

</script>

<style>
.my-special-dialog {
    width: 800px !important;
    height: 550px !important;
    border-radius: 20px !important;
}

.my-special-dialog .el-dialog__body {
    padding: 0 !important;
}

.my-special-dialog .el-dialog__header {
    padding: 0 !important;
}

.butonm-card-items {
    width: 33%;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    background-image: url('/src/static/logo.png');
    background-position: center;
    background-size: cover;
    width: 150px;
    margin-left: -60px;
}

.form-css {
    text-align: center;
}

a {
    color: white;
    font-weight: bold;
}

.el-menu-transparent {
    /* 设置背景为透明 */
    background-color: transparent !important;
}

.el-menu-transparent .el-menu-item.is-active {
    background-color: transparent !important;
    /* 设置激活状态背景色为透明 */
}

.el-menu-transparent .el-menu-item:hover {
    background-color: transparent !important;
    /* 可选：设置鼠标悬停背景色为透明 */
}

.el-menu--horizontal.el-menu {
    border-bottom: 0 !important;
}

.center-span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2vh;
    font-size: 20px;
    /* 可以根据需要调整高度 */
}

.common-layout {
    /* 设置背景图片 */
    background-image: url('/src/static/bg2.png');

    /* 设置背景图片不重复 */
    background-repeat: no-repeat;

    /* 设置背景图片大小 */
    background-size: 100% 700px;
    /* 这将确保背景图片覆盖整个页面 */

    background-color: #ededed;

    /* 设置背景图片的位置 */
    background-position: top;
    /* 这将确保背景图片居中显示 */
}

.login-dialog-mian {
    padding: 20px;
    margin-top: 50px;
}

.register-dialog-mian {
    padding: 20px;
    margin-top: 50px;
}

.register-dialog {
    height: 100%;
}

.tel-dialog-mian {
    padding: 20px;
    margin-top: 50px;
}

.top-input-register {
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: center;
    align-items: center;
}

.top-input-register-url {
    display: flex;
    width: 100%;
    height: 66px;
    justify-content: center;
    align-items: center;
}

.top-input {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
}

.top-inputwjmm {
    width: 100%;
    height: 30px;
    float: right;
}


.center-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li {
    margin-bottom: 10px;
}

.flex-grow {
    flex-grow: 1;
}

.weibu {
    margin-left: -1px;
    margin-top: 10px;
    text-align: center;
    background-image: url('/src/static/footer-bg.jpg');
    width: 100%;
    height: auto;
    margin: auto;
    /* 水平居中对齐 */
    font-family: '宋体';
    color: #faf9f9;
}

.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.el-upload--picture-card {
    width: 66px !important;
    height: 66px !important;

}

.componentlogin {
    /* 默认的文字样式 */
    color: black;
}

.componentlogin:hover {
    /* 鼠标悬浮时的文字样式 */
    color: rgb(99, 223, 240);
    /* 可以根据需要更改颜色 */
    cursor: pointer;
}

.body {
    padding: 0;
}

html,
body,
#app,
.el-container {
    padding: 0px;
    margin: 0px;
    width: auto;
}
</style>