<template>
    <el-carousel indicator-position="outside" type="card" height="380px">
        <el-carousel-item v-for="item in tableData" :key="item.photoId">
            <div class="image-container">
                <el-image class="image-container-imge" :src="item.photoUrl" @click="goToShopPlus(item.gameId)" fit="cover" />
            </div>
        </el-carousel-item>
    </el-carousel>
    <!-- 在售商品的div -->
    <div class="section1">
        <div style="width: 60%; margin: auto;">
            <!-- 标题栏的div -->
            <div style="border-bottom: 1px solid #ac8226; height: 30px; margin-left: 15px; width: 88%;">
                <span class="li-span-y">在售商品</span>
                <a class="li-a" @click="goToShop" style="float: right;">前往市场</a>
            </div>
            <!-- 商品展示div -->
            <div style="margin-top: 8px;">
                <el-space wrap style="margin-bottom: 2%;">
                    <el-card v-for="i in wareData" :key="i" class="box-card">
                        <div style="width: 100%; height: 100%;" @click="goToshopdetails(i.waresId)">
                            <div class="card-list" style="height: 70%;">
                                <el-image style="height: 100%; width: 100%;" fit="fill" :src="i.waresPhoto" />
                            </div>
                            <div style="height: 15%; margin-left: 10px;">
                                <span class="component">{{ i.waresName }}</span>
                            </div>
                            <div style="height: 15%; margin-left: 10px; font-weight: bold; color: #FFD700;">
                                <span>¥ {{ i.waresPrice }}</span>
                            </div>
                        </div>

                    </el-card>
                </el-space>
            </div>
        </div>
    </div>

    <div class="section2">
        <!-- 已售订单的div -->
        <div style="width: 60%; margin: auto;">
            <!-- 标题栏的div -->
            <div style="border-bottom: 1px solid #334d7c; height: 30px; margin-left: 15px; width: 88%;">
                <span class="li-span-x">成交记录</span>
                <a class="li-a" @click="goToShop" style="float: right;">前往市场</a>
            </div>
            <!-- 订单展示div -->
            <div style="margin-top: 8px;">
                <el-space wrap style="margin-bottom: 2%;">
                    <el-card v-for="i in goodsData" :key="i" class="box-card">
                        <div style="width: 100%; height: 100%;" >
                            <div class="card-list" style="height: 70%;">
                                <el-image style="height: 100%; width: 100%;" fit="fill" :src="i.goodsPhoto" />
                            </div>
                            <div style="height: 15%; margin-left: 10px;">
                                <span class="component">{{ i.waresName }}</span>
                            </div>
                            <div style="height: 15%; margin-left: 10px; font-weight: bold; color: #FFD700;">
                                <span>¥ {{ i.waresPrice }}</span>
                            </div>
                        </div>
                    </el-card>
                </el-space>
            </div>
        </div>
    </div>

    <div class="section3">
        <!-- 已售订单的div -->
        <div style="width: 60%; margin: auto;">
            <!-- 标题栏的div -->
            <div style="border-bottom: 1px solid #ac8226; height: 30px; margin-left: 15px; width: 88%;">
                <span class="li-span-y">热门商品</span>
                <a class="li-a" @click="goToShop" style="float: right;">前往市场</a>
            </div>
            <!-- 订单展示div -->
            <div style="margin-top: 8px;">
                <el-space wrap style="margin-bottom: 2%;">
                    <el-card v-for="i in hotData" :key="i" class="box-card">
                        <div style="width: 100%; height: 100%; " @click="goToshopdetails(i.waresId)">
                            <div class="card-list" style="height: 70%;">
                                <el-image style="height: 100%; width: 100%;" :src="i.waresPhoto" />
                            </div>
                            <div style="height: 15%; margin-left: 10px;">
                                <span class="component">{{ i.waresName }}</span>
                            </div>
                            <div style="height: 15%; margin-left: 10px; font-weight: bold; color: #FFD700;">
                                <span>¥ {{ i.waresPrice }}</span>
                            </div>
                        </div>
                    </el-card>
                </el-space>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import photoApi from '@/api/photo';
import waresApi from '@/api/wares';
import infogoodsApi from '@/api/infogoods';
import { useRouter } from 'vue-router';
import { ElImage, ElMessage } from 'element-plus';
export default {
    setup(props, { emit }) {
        // 表格数据
        const tableData = reactive([]);
        const wareData = reactive([]);
        const goodsData = reactive([]);
        const hotData = reactive([]);
        const router = useRouter();

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            photoApi.getListUser().then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data);
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //获取在售商品信息
        const wareDatas = async () => {
            // 模拟异步操作
            waresApi.getListUser().then((res) => {
                if (res.code == 200) {
                    wareData.splice(0, wareData.length);
                    wareData.push(...res.data.slice(0, 10));
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //根据商品id跳转至商品详情页面
        const goToshopdetails = (waresId) => {
            router.push({ path: '/shopdetails', query: { waresId: waresId } });
        }
        //获取成交订单信息
        const GoodsDatas = async () => {
            // 模拟异步操作
            infogoodsApi.getDoneList().then((res) => {
                if (res.code == 200) {
                    goodsData.splice(0, goodsData.length);
                    goodsData.push(...res.data.slice(0, 10));
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        //获取成交订单信息
        const hotDatas = async () => {
            // 模拟异步操作
            waresApi.getHotList().then((res) => {
                if (res.code == 200) {
                    hotData.splice(0, goodsData.length);
                    hotData.push(...res.data.slice(0, 10));
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //跳转至购物页面
        const goToShop = () => {
            router.push({ path: '/shop' })
        }

        //跳转至购物页面
        const goToShopPlus = (gameId) => {
            router.push({ path: '/shop', query: { gameId: gameId } });
        }
        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
            wareDatas();
            GoodsDatas();
            hotDatas();
        });

        return {
            fetchData,
            tableData,
            wareDatas,
            wareData,
            GoodsDatas,
            goodsData,
            hotDatas,
            hotData,
            goToShop,
            goToshopdetails,
            goToShopPlus
        };
    },
}
</script>

<style>
.section1 {
    background-image: url('/src/static/index-section1-bg.jpg');
}

.section2 {
    background-image: url('/src/static/index-section2-bg.jpg');
}

.section3 {
    background-image: url('/src/static/index-section3-bg.jpg');
}

.card-list {
    width: 185px !important;
    background-image: url('/src/static/item_bg.png');
}

.component {
  /* 默认的文字样式 */
  color: black;
}
.component:hover {
  /* 鼠标悬浮时的文字样式 */
  color: rgb(255, 215, 0); /* 可以根据需要更改颜色 */
  cursor: pointer;
}

.li-a {
    width: 70px;
    height: 30px;
    color: grey;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.li-span-x {
    background-color: #334d7c;
    width: 100px;
    height: 30px;
    position: absolute;
    color: aliceblue;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.li-span-y {
    background-color: #ac8226;
    width: 100px;
    height: 30px;
    position: absolute;
    color: aliceblue;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-card {
    width: 185px;
    height: 180px;
    margin-left: 10px;
    padding: 0;
}

.el-card__body {
    padding: 0 !important;
}


.image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* 防止图片溢出容器 */
}

.image-container-imge {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 图片自适应拉伸 */
}
</style>
