import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/cptcategory/" + data);
  },
  getListUser() {
    return http.get("/cptcategory");
  },
  save(data) {
    return http.post("/cptcategory", data);
  },
  delete(data) {
    return http.delete("/cptcategory/"+data);
  },
  getPage(params) {
    return http.get("/cptcategory/page", { params });
  },
};
