<template>
  <div class="container">
    <el-card class="personal-info-card">
      <el-container style="min-height: 100%">
        <el-aside class="el-aside">
            <h3 class="h-user">个 人 中 心</h3>
            <el-menu :default-active="activeIndex" class="el-menu-person" router>
              <el-menu-item index="/wallet"  :route="{ name: 'wallet' }" class="muen-items-centers" >
                <el-icon>
                  <WalletFilled />
                </el-icon>
                <span>我的钱包</span>
              </el-menu-item>
              <el-menu-item index="/userset" :route="{ name: 'userset' }" class="muen-items-centers">
                <el-icon>
                  <Setting />
                </el-icon>
                <span>账号设置</span>
              </el-menu-item>
              <el-menu-item index="/sale" :route="{ name: 'sale' }" class="muen-items-centers">
                <el-icon>
                  <DocumentChecked />
                </el-icon>
                <span>我的出售</span>
              </el-menu-item>
              <el-menu-item index="/purchase" :route="{ name: 'purchase' }" class="muen-items-centers">
                <el-icon>
                  <DocumentChecked />
                </el-icon>
                <span>我的购买</span>
              </el-menu-item>
              <el-menu-item index="/problem" :route="{ name: 'problem' }" class="muen-items-centers">
                <el-icon>
                  <DocumentChecked />
                </el-icon>
                <span>问题反馈</span>
              </el-menu-item>
            </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
import { ref , watch } from 'vue';
import userApi from '@/api/user'
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'Personal',
  setup() {
    const router = useRouter();
    const activeIndex = ref(router.currentRoute.value.path);
    // 使用 watch 监听路由变化
    watch(
            () => router.currentRoute.value,
            (newRoute, oldRoute) => {
                activeIndex.value = newRoute.path;
            },
            { immediate: true } // 立即执行回调，用于初始化 activeIndex
        );
    onMounted(() => {
      
    });
    return {
      activeIndex
    };
  },
};
</script>

<style scoped>
.h-user {
  width: 100%;
  margin-top: -5px;
  text-align: center;
}
.el-aside {
  width: 200px !important;
}
.el-menu-person {
  width: 100%;
  /* 宽度占据整个容器 */
  height: 88%;
  /* 高度占据整个容器 */
}

.muen-items-centers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  /* 设置容器高度为视口高度的 70% */
  overflow: auto;
  /* 当内容超出容器时显示滚动条 */
  flex-direction: column;
  /* 垂直排列子元素 */
  padding: 10px;
  /* 添加一些内边距，使内容不会紧贴边缘 */
}

.personal-info-card {
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  /* 垂直居中子元素 */
  justify-content: center;
  /* 水平居中子元素 */
  text-align: center;
  /* 文本水平居中 */
  width: 100%;
  height: 100%;
  /* 使卡片宽度自动适应内容宽度 */
  max-width: 1000px;
  /* 限制卡片最大宽度，防止在过宽的屏幕上过大 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* 添加一些阴影以改善视觉效果 */
  padding: 20px;
  /* 添加内边距，使内容不会紧贴卡片边缘 */
}
</style>