import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/goods/" + data);
  },
  goToDelivery(data) {
    return http.get("/goods/delivery/" + data);
  },
  goToTakeDelivery(data){
    return http.get("/goods/takeDelivery/" + data);
  },
  getListUser() {
    return http.get("/goods");
  },
  getDoneList() {
    return http.get("/goods/getDoneGoods");
  },
  getDayDoods() {
    return http.get("/goods/getDayDoods");
  },
  save(data) {
    return http.post("/goods", data);
  },
  delete(data) {
    return http.delete("/goods/"+data);
  },
  getPage(params) {
    return http.get("/goods/page", { params });
  },
  getPage2(params) {
    return http.get("/goods/page2", { params });
  },
  getTrendData(){
    return http.get("/goods/getTrendData");
  }
};