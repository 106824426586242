<template>
    <div class="div-box-shop">
        <div style="display: flex; background-color: #1c202b; height: 50px;">
            <div style="width: 80%; margin-right: 2%; margin-left: 5%; margin-top: 10px;">
                <el-input v-model="params.waresName" style="width: 90%" placeholder="请输入商品名称" />
                <el-button type="primary" style="margin: 0; width: 10%;" @click="fetchData"><el-icon>
                        <Search />
                    </el-icon>搜索</el-button>
            </div>
            <div style="width: 10%; margin-top: 10px;">
                <el-dropdown>
                    <el-button type="primary">
                        排序<el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="onItemClick(0)">默认</el-dropdown-item>
                            <el-dropdown-item @click="onItemClick(1)">价格<el-icon>
                                    <Top />
                                </el-icon></el-dropdown-item>
                            <el-dropdown-item @click="onItemClick(2)">价格<el-icon>
                                    <Bottom />
                                </el-icon></el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div style="width: 10%; margin-top: 10px;">
                <el-button type="primary" @click="addRecord">我要出售</el-button>
            </div>
        </div>
        <div class="div-box-shop-main">
            <div class="card-container">
                <el-card class="card-item1" shadow="hover" v-for="item in tableData" :key="item.waresId">
                    <div style="height: 100%; width: 100%;" @click="goToshopdetails(item.waresId)">
                        <div style="height: 65%;" class="top-iamge">
                            <el-image style="height: 100%; width: 100%;" :src="item.waresPhoto" fit="fill" />
                        </div>
                        <div style="height: 35%;">
                            <div style="margin-top: 10px; margin-left: 10px;">
                                <span style="font-size: large;" class="component">
                                    {{ item.waresName }}
                                </span>
                            </div>
                            <div style="margin-top: 10px; margin-left: 15px; font-weight: bold; color: #FFD700;">
                                <span style="font-size: large;">
                                    ¥ {{ item.waresPrice }}
                                </span>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
            <el-pagination style="height: 60px;" @size-change="handlePageSizeChange"
                @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize" background layout="prev, pager, next" :total="totalItems">
            </el-pagination>
        </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="出售商品" width="350" center>
        <el-form :model="form" :rules="rules" label-width="60px">
            <el-form-item label="名称" prop="waresName">
                <el-input v-model="form.waresName" placeholder="请输入名称" style="width: 214px;"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="waresDetails">
                <el-input v-model="form.waresDetails" type="textarea" placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="waresPhoto">
                <el-upload class="upload-demo" ref="upload" action="#" :show-file-list="false" :on-change="handleChange"
                    :auto-upload="false" list-type="picture-card">
                    <img v-if="form.waresPhoto" :src="form.waresPhoto" class="avatar" width="55" height="55">
                    <el-icon v-else class="avatar-uploader-icon">
                        <Plus />
                    </el-icon>
                </el-upload>
            </el-form-item>
            <el-form-item label="价格" prop="waresPrice">
                <el-input v-model="form.waresPrice" placeholder="请输入出售价格" style="width: 214px;"
                    oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="游戏" prop="waresPrice">
                <el-select v-model="form.gameId" placeholder="请选择类别">
                    <el-option v-for="game in gameDatas.value" :key="game.gameId" :label="game.gameName"
                        :value="game.gameId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类别" prop="waresPrice">
                <el-select v-model="form.waresGroup" placeholder="请选择类别">
                    <el-option v-for="group in groups" :key="group.value" :label="group.label" :value="group.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="save">
                    上架
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>


<script>
import waresApi from '@/api/wares';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import fileApi from '@/api/file';
import gameApi from '@/api/gameinfo';
import { ElImage, ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
export default {
    setup() {
        //表格数据
        const tableData = reactive([]);
        const currentPage = ref(1); // 当前页码
        const pageSize = ref(20); // 每页显示的数据条数
        const totalItems = ref(0); // 数据的总条数
        const router = useRouter();
        //出售商品弹窗
        const centerDialogVisible = ref(false);

        const user = JSON.parse(localStorage.getItem('user'));
        // 表单数据
        const form = reactive({
            waresId: null,
            waresName: '',
            waresDetails: '',
            waresPhoto: '',
            waresPrice: null,
            isTrueWares: '',
            createTime: '',
            updateTime: '',
            userId: null,
            gameId: null,
            waresGroup: null
        });
        //设置类别值
        const groups = ref([
            { label: '货币', value: '货币' },
            { label: '道具', value: '道具' },
        ])
        //定义文件类型
        const file = ref(null);
        //存储游戏列表
        const gameDatas = reactive({});
        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });
        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            waresName: '',
            status: 0,
            gameId: 0
        })

        // 表单验证规则
        const rules = {
            waresName: [
                { required: true, message: '请输入商品名称', trigger: 'blur' },
            ],
            waresPrice: [
                { required: true, message: '请输入商品价格', trigger: 'blur' },
            ],
            gameId: [
                { required: true, message: '请选择游戏', trigger: 'blur' },
            ],
            waresGroup: [
                { required: true, message: '请选择类别', trigger: 'blur' },
            ],
            // ... 其他表单项的验证规则
        };

        // 上传头像前的处理函数
        const handleChange = function (rawFile) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
            if (!allowedTypes.includes(rawFile.raw.type)) {
                ElMessage.error('Avatar picture must be JPG format!');
                return false;
            } else if (rawFile.raw.size / 1024 / 1024 > 2) {
                ElMessage.error('Avatar picture size can not exceed 2MB!');
                return false;
            }
            form.waresPhoto = rawFile.url;
            file.value = rawFile
            return true;
        }

         // 新增记录
         const addRecord = () => {
            centerDialogVisible.value = true;
            form.waresId = null,
            form.waresName = '';
            form.waresDetails = '';
            form.waresPhoto = '';
            form.waresPrice = null;
            form.isTrueWares = '';
            form.gameId = null;
            form.waresGroup =  '';
            form.userId = null;
        };

        //获取游戏列表，用于选择所属游戏
        const getGames = () => {
            gameApi.getListUser().then(res => {
                if (res.code == 200) {
                    gameDatas.value = res.data;
                } else {
                    ElMessage.error("获取游戏列表失败")
                }
            })
        }

        //根据商品id跳转至商品详情页面
        const goToshopdetails = (waresId) => {
            router.push({ path: '/shopdetails', query: { waresId: waresId } });
        }

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };
        const onItemClick = (index) => {
            params.status = index;
            tableData.length = 0;
            fetchData();
        }
        //进行新增
        const save = async () => {
            if (form.waresName == null) {
                ElMessage.error("请填写名称")
                return
            }
            //判断商品价格是否填写
            if (form.gameId == null) {
                ElMessage.error("请填写价格")
                return
            }
            //判断商品所属游戏是否填写
            if (form.waresGroup == null) {
                ElMessage.error("请选择游戏")
                return
            }
            //判断商品类别是否填写
            if (form.waresPrice == null) {
                ElMessage.error("请选择商品类别")
                return
            }
            form.userId = user.userId;
            //文件等于null说明这次没有上传文件
            if (file.value != null) {
                console.log(file.value.raw)
                let formData = new FormData();
                formData.append('file', file.value.raw);
                fileApi.uploadFile(formData).then(ress => {
                    if (ress.code == 200) {
                        form.waresPhoto = ress.data;
                        file.value = null;
                        waresApi.save(form).then(res => {
                            if (res.code == 200) {
                                centerDialogVisible.value = false;
                                ElMessage.success("上架成功")
                                fetchData();
                            } else {
                                ElMessage.error("上架失败")
                            }
                        })
                    } else {
                        ElMessage.error("上传失败")
                    }
                })
            } else {
                waresApi.save(form).then(res => {
                    if (res.code == 200) {
                        centerDialogVisible.value = false;
                        file.value = null;
                        ElMessage.success("上架成功")
                        fetchData();
                    } else {
                        ElMessage.error("上架失败")
                    }
                })
            }
        }

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            waresApi.getPage2(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                    if (res.data.total == 0) {
                        ElMessage.info("未搜索到商品")
                    }
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        // 初始化数据，获取表格数据
        onMounted(() => {
            if (router.currentRoute.value.query.gameId != null) {
                params.gameId = router.currentRoute.value.query.gameId;
            } else {
                params.gameId = 0;
            }
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
            getGames();
        });

        return {
            tableData,
            fetchData,
            currentPage,
            pageSize,
            totalItems,
            paginatedData,
            params,
            handlePageSizeChange,
            handleCurrentChange,
            onItemClick,
            goToshopdetails,
            centerDialogVisible,
            form,
            groups,
            gameDatas,
            getGames,
            rules,
            handleChange,
            save,
            addRecord
        }
    }
}
</script>

<style>
.div-box-shop {
    width: 60%;
    height: 1100px;
    margin: auto;
    background-color: #fafafa;
    /* 假设这是卡片的父容器 */
    display: flex;
    flex-direction: column;
    /* 设置flex方向为列，确保卡片垂直排列 */
    align-items: stretch;
    /* 确保卡片填满可用空间 */
    margin-top: 60px;
}

.div-box-shop-main {
    width: 96%;
    height: 88%;
    margin-bottom: 1.5%;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
}
.component {
  /* 默认的文字样式 */
  color: black;
}
.component:hover {
  /* 鼠标悬浮时的文字样式 */
  color: rgb(255, 215, 0); /* 可以根据需要更改颜色 */
  cursor: pointer;
}

.card-item1 {
    flex: 0 0 18%;
    /* 四个卡片，所以每个卡片的宽度为 23% (24% - 1% 的间距) */
    margin-right: 1%;
    /* 每个卡片之间的间距 */
    margin-left: 1%;
    /* 每个卡片之间的间距 */
    margin-top: 0.5%;
    /* 每个卡片之间的垂直间距 */
    margin-bottom: 0.5%;
    box-sizing: border-box;
    /* 确保宽度包括 padding 和 border */
    height: 230px;
}
.top-iamge {
    background-image: url('/src/static/item_bg.png')
}
.el-card:hover {
    box-shadow: 4PX 3px 3px rgba(231, 202, 39, 0.596) !important;
}
</style>