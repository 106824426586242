<template>
    <div class="div-box-usersetmain">
        <div class="div-box-usersetmain-top">
            <h3>账号设置</h3>
        </div>
        <div class="div-box-usersetmain-middle">
            <h5>基础账号信息</h5>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">头像</div>
                <el-avatar :src="userFrom.avatarUrl" style="width: 35px; height: 35px;" fit="fill"></el-avatar>
                <el-button type="primary" style="width: 100px; height: 40px;"
                    @click="dialogFormavatarUrl = true"><el-icon>
                        <EditPen />
                    </el-icon>更换头像</el-button>
            </div>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">昵称</div>
                <el-input v-model="userFrom.nickName" disabled style="width: 240px" placeholder="请输入昵称" />
                <el-button type="primary" style="width: 100px; height: 40px;"
                    @click="dialogFormVisibles = true"><el-icon>
                        <EditPen />
                    </el-icon>修改昵称</el-button>
            </div>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">账号</div>
                <el-input v-model="userFrom.userAccount" disabled style="width: 240px" />
            </div>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">密码</div>
                <form style="line-height: 35px;">
                    <el-input v-model="userFrom.userPassWrod" disabled type="password"
                        style="width: 240px; height: 40px;" />
                </form>
                <el-button type="primary" style="width: 100px; height: 40px;"
                    @click="dialogFormPassword = true"><el-icon>
                        <EditPen />
                    </el-icon>修改密码</el-button>
            </div>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">创建时间</div>
                <el-input v-model="userFrom.createTime" disabled style="width: 240px" />
            </div>
            <h5>身份认证信息</h5>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">手机帐号</div>
                <el-input v-model="userFrom.userTel" disabled style="width: 240px" placeholder="请输入手机账号" />
                <el-button @click="dialogFormVisiblestel = true" type="primary"
                    style="width: 100px; height: 40px;"><el-icon>
                        <EditPen />
                    </el-icon>更换手机</el-button>
            </div>
            <div class="div-box-usersetmain-middle-item">
                <div class="div-box-usersetmain-middle-item-s">实名认证</div>
                <el-input v-model="userFrom.userIdCard" disabled style="width: 240px" placeholder="暂无身份信息" />
                <el-button v-if="userFrom.userIdCard == ''" @click="dialogFormVisiblesuserIdCard = true" type="primary"
                    style="width: 100px; height: 40px;">
                    <el-icon>
                        <EditPen />
                    </el-icon>填写身份信息</el-button>
            </div>
        </div>
    </div>
    <!-- 填写身份信息弹窗 -->
    <el-dialog v-model="dialogFormVisiblesuserIdCard" title="填写身份信息" width="500">
        <el-form :model="userFrom">
            <el-form-item label="身份证号" label-width="100">
                <el-input v-model="userFrom.userIdCard" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogFormVisiblesuserIdCard = false">取消</el-button>
                <el-button type="primary" @click="setUserIdCard">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>
    <!-- 更换昵称弹窗 -->
    <el-dialog v-model="dialogFormVisibles" title="修改昵称" width="500">
        <el-form :model="userFrom">
            <el-form-item label="昵称" label-width="80">
                <el-input v-model="userFrom.nickName" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogFormVisibles = false">取消</el-button>
                <el-button type="primary" @click="save">
                    确认修改
                </el-button>
            </div>
        </template>
    </el-dialog>
    <!-- 更换手机账号弹窗 -->
    <el-dialog v-model="dialogFormVisiblestel" title="更换手机账号" width="500">
        <el-form :model="userFrom">
            <el-form-item v-if="index == 0" label="手机" label-width="80">
                <el-input disabled v-model="userFrom.userTel" autocomplete="off" />
            </el-form-item>
            <el-form-item v-if="index == 0" label="验证码" label-width="80">
                <el-input style="width: 68%;" v-model="userTelForm.smsCode" autocomplete="off" />
                <el-button v-if="!sms.disabled" style="width: 30%; border-radius: 10px; margin-left: 3px;"
                    @click="sendSMS">获取验证码</el-button>
                <el-button v-else disabled style="width: 30%; border-radius: 10px; margin-left: 3px;">{{ sms.count
                    }}秒后重新获取</el-button>
            </el-form-item>
            <el-form-item v-if="index == 1" label="新手机号" label-width="80">
                <el-input v-model="userTelForm.userTel" autocomplete="off" />
            </el-form-item>
            <el-form-item v-if="index == 1" label="验证码" label-width="80">
                <el-input style="width: 68%;" v-model="userTelForm.smsCode" autocomplete="off" />
                <el-button v-if="!sms.disabled" style="width: 30%; border-radius: 10px; margin-left: 3px;"
                    @click="newSendSMS">获取验证码</el-button>
                <el-button v-else disabled style="width: 30%; border-radius: 10px; margin-left: 3px;">{{ sms.count
                    }}秒后重新获取</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogFormVisiblestel = false">取消</el-button>
                <el-button v-if="index == 0" type="primary" @click="updateTel">
                    确认
                </el-button>
                <el-button v-if="index == 1" type="primary" @click="updateTelS">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>
    <!-- 更换头像弹窗 -->
    <el-dialog v-model="dialogFormavatarUrl" title="更换头像" width="500">
        <el-form :model="userFrom">
            <el-form-item label="头像" label-width="80">
                <el-upload class="upload-demo" ref="upload" action="#" :show-file-list="false" :on-change="handleChange"
                    :auto-upload="false" list-type="picture-card">
                    <img v-if="userFrom.avatarUrl" :src="userFrom.avatarUrl" class="avatar" width="148" height="148">
                    <el-icon v-else class="avatar-uploader-icon">
                        <Plus />
                    </el-icon>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="isfalse">取消</el-button>
                <el-button type="primary" @click="save">
                    确认修改
                </el-button>
            </div>
        </template>
    </el-dialog>
    <!-- 修改密码对话框弹窗 -->
    <el-dialog v-model="dialogFormPassword" title="修改密码" width="500">
        <el-form :model="userPassForm">
            <el-form-item label="请输入当前密码" label-width="120">
                <el-input type="password" v-model="userPassForm.oldPassword" autocomplete="off" />
            </el-form-item>
            <el-form-item label="请输入新密码" label-width="120">
                <el-input type="password" v-model="userPassForm.newPassword" autocomplete="off" />
            </el-form-item>
            <el-form-item label="请确认新密码" label-width="120">
                <el-input type="password" v-model="userPassForm.checkNewPassword" autocomplete="off" />
            </el-form-item>
            <div v-if="isEqual"></div>
            <div v-else style="font-size: 12px; color: red; height: 20px; margin-left: -100px;">两次输入的密码不同</div>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogFormPassword = false">取消</el-button>
                <el-button type="primary" @click="updatePass">
                    确认修改
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, reactive, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import userApi from '@/api/user';
import fileApi from '@/api/file';
import { MD5 } from 'crypto-js';
export default {
    setup() {
        const router = useRouter();
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.userId;
        const dialogFormVisibles = ref(false);
        const dialogFormavatarUrl = ref(false);
        const dialogFormPassword = ref(false);
        const dialogFormVisiblestel = ref(false);
        const dialogFormVisiblesuserIdCard = ref(false);


        const oldavatarUrl = ref('');
        // 表单数据
        const userFrom = reactive({
            userId: null,
            userAccount: '',
            nickName: '',
            userTel: '',
            userIdCard: '',
            userBalance: '',
            userPassWrod: '',
            avatarUrl: '',
            createTime: ''
        });
        const index = ref(0)

        const userTelForm = reactive({
            userId: null,
            userTel: '',
            smsCode: ''
        })

        const userPassForm = reactive({
            oldPassword: '',
            newPassword: '',
            checkNewPassword: ''
        })

        const userPassFormT = reactive({
            userId: null,
            oldPassword: '',
            newPassword: '',
            checkNewPassword: ''
        })

        // 验证码计时器
        const sms = reactive({
            disabled: false,
            total: 60,
            count: 0
        })

        // 计时器处理器
        const timerHandler = () => {
            sms.count = sms.total
            sms.disabled = true

            let timer = setInterval(() => {
                if (sms.count > 1 && sms.count <= sms.total) {
                    sms.count--
                } else {
                    sms.disabled = false
                    clearInterval(timer)
                }
            }, 1000)
        }

        //修改方法
        const updatePass = () => {
            userPassFormT.oldPassword = MD5(userPassForm.oldPassword).toString();
            userPassFormT.newPassword = MD5(userPassForm.newPassword).toString();
            userPassFormT.checkNewPassword = MD5(userPassForm.checkNewPassword).toString();
            if (userPassFormT.newPassword != userPassFormT.checkNewPassword) {
                ElMessage.error("两次输入的密码不同")
                return;
            }
            userApi.upDataPassword(userPassFormT).then(res => {
                if (res.code == 200) {
                    ElMessage.success(res.msg)
                    userPassFormT.oldPassword = '';
                    userPassFormT.newPassword = '';
                    userPassFormT.checkNewPassword = '';
                    dialogFormPassword.value = false;
                    console.log(res.data)
                    localStorage.setItem('user', JSON.stringify(res.data));
                } else {
                    ElMessage.error(res.msg)
                }
            })
        }

        const isEqual = computed(() => {
            return userPassForm.newPassword === userPassForm.checkNewPassword;
        });


        //验证码发送
        const newSendSMS = () => {
            userApi.sendSMS(userTelForm).then((res) => {
                if (res.code == 200) {
                    timerHandler();
                    ElMessage.success("请求验证码成功")
                } else {
                    ElMessage.error("请求验证码失败")
                }
            })

        }

        //验证码发送
        const sendSMS = () => {
            userApi.smsUpdate(userTelForm).then((res) => {
                if (res.code == 200) {
                    timerHandler();
                    ElMessage.success("请求验证码成功")
                } else {
                    ElMessage.error("请求验证码失败")
                }
            })

        }
        //检查验证码是否一致
        const updateTel = () => {
            userApi.updateTel(userTelForm).then((res) => {
                if (res.code == 200) {
                    index.value = 1;
                    userTelForm.smsCode = '';
                    ElMessage.success("验证成功，请输入新的手机号并验证！")
                } else {
                    ElMessage.error("验证码错误")
                }
            })
        }
        //进行更改
        const updateTelS = () => {
            userApi.updateTelS(userTelForm).then((res) => {
                if (res.code == 200) {
                    index.value = 0;
                    dialogFormVisiblestel.value = false;
                    fetchData();
                    ElMessage.success("验证通过，手机账号修改成功")
                } else {
                    ElMessage.error("验证码错误")
                }
            })
        }
        //填写身份信息
        const setUserIdCard = () => {
            userApi.setUserIdCard(userFrom).then((res) => {
                if (res.code == 200) {
                    ElMessage.success("填写成功");
                    dialogFormVisiblesuserIdCard.value = false;
                    fetchData();
                } else {
                    ElMessage.error(res.msg);
                }
            })
        }
        //保存信息接口
        const save = async () => {
            //文件等于null说明这次没有上传文件
            if (file.value != null) {
                console.log(file.value.raw)
                let formData = new FormData();
                formData.append('file', file.value.raw);
                fileApi.uploadFile(formData).then(ress => {
                    if (ress.code == 200) {
                        userFrom.avatarUrl = ress.data;
                        userApi.save(userFrom).then(res => {
                            if (res.code == 200) {
                                dialogFormVisibles.value = false;
                                dialogFormavatarUrl.value = false;
                                ElMessage.success("保存成功")
                                user.avatarUrl = ress.data;
                                localStorage.setItem('user', JSON.stringify(user));
                                fetchData();
                            } else {
                                ElMessage.error("保存失败")
                            }
                        })
                    } else {
                        ElMessage.error("上传失败")
                    }
                })
            } else {
                userFrom.avatarUrl = oldavatarUrl.value;
                userApi.save(userFrom).then(res => {
                    if (res.code == 200) {
                        dialogFormavatarUrl.value = false;
                        dialogFormVisibles.value = false;
                        ElMessage.success("保存成功")
                        user.nickName = userFrom.nickName;
                        localStorage.setItem('user', JSON.stringify(user));
                        fetchData();
                    } else {
                        ElMessage.error("保存失败")
                    }
                })
            }
        }

        const fetchData = async () => {
            // 模拟异步操作
            userApi.getById2(user.userId).then((res) => {
                if (res.code == 200) {
                    userFrom.userId = user.userId;
                    userPassFormT.userId = user.userId;
                    userFrom.userAccount = res.data.userAccount;
                    userFrom.nickName = res.data.nickName;
                    userFrom.userTel = res.data.userTel;
                    userFrom.userIdCard = res.data.userIdCard;
                    userFrom.userBalance = res.data.userBalance;
                    userFrom.avatarUrl = res.data.avatarUrl;
                    oldavatarUrl.value = res.data.avatarUrl;
                    userFrom.userPassWrod = '123456789';
                    userFrom.createTime = res.data.createTime;
                    userTelForm.userId = user.userId;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        //定义文件类型
        const file = ref(null);
        // 上传头像前的处理函数
        const handleChange = function (rawFile) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
            if (!allowedTypes.includes(rawFile.raw.type)) {
                ElMessage.error('Avatar picture must be JPG format!');
                return false;
            } else if (rawFile.raw.size / 1024 / 1024 > 2) {
                ElMessage.error('Avatar picture size can not exceed 2MB!');
                return false;
            }
            userFrom.avatarUrl = rawFile.url;
            file.value = rawFile
            return true;
        }
        const isfalse = () => {
            dialogFormavatarUrl.value = false
            file.value = null;
            userFrom.avatarUrl = oldavatarUrl.value;
        }
        onMounted(() => {
            fetchData()
        });
        return {
            userId,
            userFrom,
            fetchData,
            user,
            dialogFormVisibles,
            handleChange,
            file,
            dialogFormavatarUrl,
            isfalse,
            save,
            userPassForm,
            userPassFormT,
            dialogFormPassword,
            isEqual,
            updatePass,
            dialogFormVisiblestel,
            userTelForm,
            sendSMS,
            updateTel,
            newSendSMS,
            index,
            updateTelS,
            timerHandler,
            sms,
            dialogFormVisiblesuserIdCard,
            setUserIdCard,
        }
    }
}
</script>

<style>
.div-box-usersetmain {
    width: 100%;
    height: 90%;
}

.div-box-usersetmain-middle {
    width: 100%;
    height: 75%;
    text-align: left;
    margin-left: 20px;
}

.div-box-usersetmain-top {
    height: 10%;
}

.div-box-usersetmain-middle-item {
    height: 40px;
    display: flex;
    margin-bottom: 10px;
}

.div-box-usersetmain-middle-item-s {
    width: 100px;
    margin-right: 10px;
    line-height: 35px;
}
</style>