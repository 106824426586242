import showingVue from "@/views/show/front/showing.vue";
import informationVue from "@/views/show/front/information.vue";
import gameclassifyVue from "@/views/show/front/gameclassify.vue";
import personalVue from "@/views/show/userViews/personal.vue";
import Home from "@/views/show/home.vue";
import Index from "@/views/manage/index.vue";
import syshomeVue from "@/views/manage/syshome.vue";
import sysuserVue from "@/views/manage/user/sysuser.vue";
import gamegroupVue from "@/views/manage/game/gamegroup.vue";
import gameinfoVue from "@/views/manage/game/gameinfo.vue";
import commoditygoodsVue from "@/views/manage/goods/commoditygoods.vue";
import complaintgoodsVue from "@/views/manage/goods/complaintgoods.vue";
import infogoodsVue from "@/views/manage/goods/infogoods.vue";
import sysphotoVue from "@/views/manage/system/sysphoto.vue";
import newsVue from "@/views/manage/system/news.vue";
import newsdetailsVue from "@/views/show/front/newsdetails.vue";
import shopVue from "@/views/show/front/shop.vue";
import detailsVue from "@/views/show/front/shopdetails.vue";
import usersetVue from "@/views/show/userViews/userset.vue";
import walletVue from "@/views/show/userViews/wallet.vue";
import problemVue from "@/views/show/userViews/problem.vue";
import errorVue from "@/views/error/404.vue";
import saleVue from "@/views/show/userViews/sale.vue";
import purchaseVue from "@/views/show/userViews/purchase.vue";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  //首页
  {
    path: "/",
    name: "home",
    redirect: "showing",
    component: Home,
    children: [
      { path: "/showing", name: "showing", component: showingVue },
      { path: "/information", name: "information", component: informationVue },
      { path: "/gameclassify", name: "gameclassify", component: gameclassifyVue},
      {
        path: "/personal",
        name: "personal",
        redirect: "wallet",
        component: personalVue,
        children: [
          { path: "/userset", name: "userset", component: usersetVue },
          { path: "/wallet", name: "wallet", component: walletVue },
          { path: "/problem", name: "problem", component: problemVue },
          { path: "/sale", name: "sale", component:saleVue },
          { path: "/purchase", name: "purchase", component: purchaseVue },
        ],
      },
      { path: "/newsdetails", name: "newsdetails", component: newsdetailsVue },
      { path: "/shop", name: "shop", component: shopVue },
      { path: "/shopdetails", name: "shopdetails", component: detailsVue },
    ],
  },
  
  {
    path: "/management",
    name: "index",
    redirect: "syshome",
    component: Index,
    children: [
      { path: "/syshome", name: "syshome", component: syshomeVue },
      { path: "/sysuser", name: "sysuser", component: sysuserVue },
      { path: "/gamegroup", name: "gamegroup", component: gamegroupVue },
      { path: "/gameinfo", name: "gameinfo", component: gameinfoVue },
      {
        path: "/commoditygoods",
        name: "commoditygoods",
        component: commoditygoodsVue,
      },
      {
        path: "/complaintgoods",
        name: "complaintgoods",
        component: complaintgoodsVue,
      },
      { path: "/infogoods", name: "infogoods", component: infogoodsVue },
      { path: "/sysphoto", name: "sysphoto", component: sysphotoVue },
      { path: "/news", name: "news", component: newsVue },
    ],
  },
  { path: "/:pathMatch(.*)", name: "404", component: errorVue }
];

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
});

export default router;
