import http from "@/utils/request";

export default {
  getById(data) {
    return http.get("/wares/" + data);
  },
  getListUser() {
    return http.get("/wares");
  },
  getHotList() {
    return http.get("/wares/hot");
  },
  getWareRelation(data) {
    return http.get("/wares/getRelation/" + data);
  },
  save(data) {
    return http.post("/wares", data);
  },
  UpWares(data){
    return http.post("/wares/UpWares", data);
  },
  delete(data) {
    return http.delete("/wares/"+data);
  },
  getPage(params) {
    return http.get("/wares/page", { params });
  },
  getPage2(params) {
    return http.get("/wares/page/getV2", { params });
  },
};
