<template>
    <div class="div-top">
        <el-menu class="el-menu-demo" mode="horizontal" background-color="#1c202b" text-color="#D3D3D3"
            active-text-color="#fff" default-active="1">
            <el-menu-item index="1" style="width: 15%; font-size: larger;" @click="onItemClick(0)">综 合</el-menu-item>
            <el-menu-item index="2" style="width: 15%; font-size: larger;" @click="onItemClick(1)">最 新</el-menu-item>
            <el-menu-item index="3" style="width: 15%; font-size: larger;" @click="onItemClick(2)">最 热</el-menu-item>
        </el-menu>
    </div>
    <div class="div-box1">
        <div class="game-news-card" v-for="newsItem in tableData" :key="newsItem.articleId">
            <div style="display: flex;">
                <div style="width: 200px;">
                    <el-image style="width: 200px; height: 125px" :src="newsItem.articleUrl" fit="fill" />
                </div>
                <div style="margin-top: 10px;">
                    <span class="xiahuaxian" style="margin-left: 20px; font-size: larger; font-weight: bold;"
                        @click="goToNews(newsItem.articleId)">
                        {{ newsItem.articleTheme }}
                    </span>
                    <div style="margin-top: 70px; margin-left: 20px; color: #999999; font-size: x-small;">
                        <span style="margin-right: 15px;">作者：{{ newsItem.userName }}</span>
                        <span style="margin-right: 15px;">来源：E-Sport</span>
                        <span>发布时间：{{ newsItem.createTime }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" background
            layout="prev, pager, next" :total="totalItems">
        </el-pagination>
    </div>
</template>

<script>
import articleApi from '@/api/article';
import { ref, reactive } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { ElImage, ElMessage } from 'element-plus';
export default {
    setup() {
        // 表格数据
        const tableData = reactive([]);
        const currentPage = ref(1); // 当前页码
        const pageSize = ref(10); // 每页显示的数据条数
        const totalItems = ref(10); // 数据的总条数
        //创建路由对象
        const router = useRouter();
        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });
        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            status: 1
        })

        const onItemClick = (index) => {
            params.status = index;
            fetchData();
        }

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };

        const addArticleNumber = (id) => {
            articleApi.addHot(id).then((res) =>{
                if(res.code == 200){
                }else{
                    ElMessage.error("网络异常")
                }
            })
        }

        const goToNews = (id) => {
            addArticleNumber(id);
            router.push({ path: '/newsdetails', query: { id: id } });
        }
        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            articleApi.getPage(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };
        // 初始化数据，获取表格数据
        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
        });

        return {
            fetchData,
            tableData,
            params,
            paginatedData,
            handlePageSizeChange,
            handleCurrentChange,
            currentPage,
            pageSize,
            totalItems,
            onItemClick,
            goToNews,
            router,
            addArticleNumber
        };
    },
}
</script>

<style>
.div-box1 {
    width: 60%;
    height: 1800px;
    margin: auto;
    background-color: #fafafa;
    /* 假设这是卡片的父容器 */
    display: flex;
    flex-direction: column;
    /* 设置flex方向为列，确保卡片垂直排列 */
    align-items: stretch;
    /* 确保卡片填满可用空间 */
}
.xiahuaxian:hover {
    text-decoration: underline;
    cursor: pointer;
}

.div-top {
    width: 60%;
    margin-left: 20%;
    background-color: rgb(58, 65, 65);
    margin-top: 60px;
}

.el-pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    /* 设置分页器的高度 */
}

.game-news-card {
    /* 每个卡片的样式 */
    display: block;
    /* 这不是必需的，因为div默认就是块级元素 */
    margin-top: 1%;
    /* 示例：卡片之间的间距 */
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    height: 8.5%;
    /* 确保卡片宽度填满父容器 */
    box-sizing: border-box;
    /* 包括边框和内边距在卡片的总宽度内 */
}
</style>