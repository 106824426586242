<template>
    <div id="app">
        <!-- 新增记录按钮 -->
        <el-button type="primary" style="float: right;" @click="fetchData">搜索</el-button>
        <el-input style="width: 200px; cursor: pointer; float: right;" suffix-icon="el-icon-search" placeholder="请输入订单编号"
            v-model="params.goodsCode"></el-input>
        <!-- 用户表格 -->
        <el-table :data="tableData" style="width: 100%">
            <!-- 表格列定义 -->
            <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
            <el-table-column prop="goodsCode" label="订单编号" width="180" align="center"></el-table-column>
            <el-table-column prop="buyerId" label="订单买方" width="180" align="center">
                <template #default="scope">
                    <div v-for="i in userIds">
                        <span v-if="scope.row.buyerId == i.userId">
                            {{ i.nickName }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sellerId" label="订单卖方" width="200" align="center">
                <template #default="scope">
                    <div v-for="i in userIds">
                        <span v-if="scope.row.sellerId == i.userId">
                            {{ i.nickName }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="isTrueGoods" label="订单是否成功" width="150" align="center">
                <template #default="scope">
                    <el-tag v-if="scope.row.isTrueGoods == 0" type="danger">交易失败</el-tag>
                    <el-tag v-if="scope.row.isTrueGoods == 1" >交易中</el-tag>
                    <el-tag v-if="scope.row.isTrueGoods == 2" type="success">交易成功</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="goodsPhoto" label="订单交易图片" width="150" align="center">
                <template #default="scope">
                    <img v-if="scope.row.goodsPhoto" :src="scope.row.goodsPhoto"
                        style="height: 30px; width: 30px; border-radius: 50%" />
                    <span v-else>暂无</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="订单创建时间" width="180" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="订单信息更改时间" width="180" align="center"></el-table-column>
            <el-table-column label="订单状态" align="center">
                <template #default="scope">
                    <el-tag v-if="scope.row.goodsState == 0" type="danger">交易初始阶段</el-tag>
                    <el-tag v-if="scope.row.goodsState == 1" type="warning">生产者已确认</el-tag>
                    <el-tag v-if="scope.row.goodsState == 2" >消费者已确认</el-tag>
                    <el-tag v-if="scope.row.goodsState == 3" type="success">订单结束</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button type="danger" @click="deleteRecord(scope.row.goodsId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handlePageSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="totalItems">
        </el-pagination>

        <!-- 编辑/新增对话框 
        <el-dialog v-model="dialogVisible" title="新增/编辑记录">
            <el-form :model="form" :rules="rules" label-width="120px">
                <el-form-item label="名称" prop="waresName">
                    <el-input v-model="form.waresName"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="waresDetails">
                    <el-input v-model="form.waresDetails"></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="waresPhoto">
                    <el-input v-model="form.waresPhoto"></el-input>
                </el-form-item>
                <el-form-item label="价格" prop="waresPrice">
                    <el-input v-model="form.waresPrice"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="save(form)">确定</el-button>
            </span>
        </el-dialog>-->
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import infogoodsApi from '@/api/infogoods';
import userApi from '@/api/user';
import { ElMessage } from 'element-plus';
import { onMounted } from 'vue';
import { computed } from 'vue';
export default {
    setup() {
        // 表格数据
        const tableData = reactive([]);

        // 对话框可见性
        const dialogVisible = ref(false);

        const currentPage = ref(1); // 当前页码
        const pageSize = ref(10); // 每页显示的数据条数
        const totalItems = ref(0); // 数据的总条数

        //设置用户值
        const userIds = ref([]);
        // 计算属性，用于获取当前页的数据
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = start + pageSize.value;
            return tableData.value.slice(start, end);
        });

        const handlePageSizeChange = (val) => {
            pageSize.value = val;
            fetchData();
            //currentPage.value = 1; // 重置到第一页
        };
        // 处理页码变化
        const handleCurrentChange = (val) => {
            currentPage.value = val;
            fetchData();
        };

        //分页查询数据填写
        const params = reactive({
            pageNum: currentPage,
            pageSize: pageSize,
            goodsCode: ''
        })

        // 表单数据
        const form = reactive({
            goodsId: null,
            goodsCode: '',
            buyerId: 0,
            sellerId: 0,
            isTrueGoods: 0,
            goodsState: 0,
            createTime: '',
            updateTime: '',
            goodsPhoto: '',
        });

        // 表单验证规则
        const rules = {
            waresName: [
                { required: true, message: '请输入商品名称', trigger: 'blur' },
            ],
            waresPrice: [
                { required: true, message: '请输入商品价格', trigger: 'blur' },
            ],
            // ... 其他表单项的验证规则
        };


        // 删除记录
        const deleteRecord = (id) => {
            infogoodsApi.delete(id).then(res => {
                if (res.code == 200) {
                    ElMessage.success("删除成功")
                    fetchData();
                } else {
                    ElMessage.error("删除失败")
                }
            })
        }

        const getUserList = async () => {
            // 模拟异步操作
            userApi.getListUser().then((res) => {
                if (res.code == 200) {
                    userIds.value = res.data;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        // 假设的获取数据函数，通常这里会是API调用
        const fetchData = async () => {
            // 模拟异步操作
            infogoodsApi.getPage(params).then((res) => {
                if (res.code == 200) {
                    tableData.splice(0, tableData.length);
                    tableData.push(...res.data.records);
                    totalItems.value = res.data.total;
                } else {
                    ElMessage.error("加载失败")
                }
            })
        };

        // 初始化数据，获取表格数据

        onMounted(() => {
            // 页面加载完成后同步调用 fetchData 方法
            fetchData();
            getUserList();
        });

        // 返回需要暴露给模板的响应式数据和方法
        return {
            tableData,
            dialogVisible,
            form,
            rules,
            deleteRecord,
            paginatedData,
            currentPage,
            pageSize,
            totalItems,
            handlePageSizeChange,
            handleCurrentChange,
            fetchData,
            getUserList,
            userIds,
            params
        };
    },
};
</script>

<style>
/* 表格样式 */
.el-table {
    width: 100%;
    margin-bottom: 20px;
}

/* 表格列样式 */
.el-table__body-wrapper {
    overflow-x: auto;
}

.el-table__body tr td {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* 对话框样式 */
.el-dialog {
    width: 50%;
    /* 或者你可以设置具体的像素值 */
}

/* 对话框内容样式 */
.el-dialog__body {
    padding: 20px;
}

/* 对话框底部按钮样式 */
.el-dialog__footer {
    text-align: right;
    padding: 10px 20px;
    border-top: 1px solid #eee;
}

/* 其他样式 */
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

.el-button {
    margin-left: 10px;
}
</style>